import React, { useState } from "react";
import "./AboutInfo.css";
import "./AboutInfo";
import AboutApi from "../aboutapi/AboutApi";
//import AboutMarketing from "../aboutmarketing/AboutMarketing";

const AboutInfo = () => {
  const [isOpen, setIsOpen] = useState("");

  const toggleSection = (section) => {
    if (isOpen === section) {
      setIsOpen("");
    } else {
      setIsOpen(section);
    }
  };

  return (
    <div>
      <div id="portfolio" className="portfolio">
        <div className="container">
          <div className="row">
            <div className="about col-lg-6 col-md-6 justify-content-center">
              <button
                onClick={() => toggleSection("section1")}
                aria-controls="section1"
                aria-expanded={isOpen === "section1"}
                variant="primary"
                className="button icon-box-green d-flex align-items-center"
              >
                <div className="justify-content-center my-4">
                  <h4>Integraciones de API</h4>
                  <p>
                    Integre el correo electrónico en su aplicación o sitio web
                    con RadarSystems.
                  </p>
                  <div className="celda">
                    <i className="bi bi-arrow-down-circle-fill link"> Ver Más</i> 
                  </div>
                </div>
              </button>
            </div>
            <div className="about col-lg-6 col-md-6 justify-content-center">
              <button
                onClick={() => toggleSection("section2")}
                aria-controls="section2"
                aria-expanded={isOpen === "section2"}
                variant="primary"
                className="button icon-box d-flex align-items-center"
              >
                <div className="justify-content-center my-4">
                  <h4>Plataforma de Marketing</h4>
                  <p>
                    Diseñar y enviar campañas de email marketing y
                    automatizaciones.
                  </p>
                  {/*<div className="celda">
                    <i className="bi bi-arrow-down-circle-fill link"> Ver Más</i> 
  </div>*/}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen === "section1" && <AboutApi />}
     {/* {isOpen === "section2" && <AboutMarketing />}*/} 
    </div>
  );
};

export default AboutInfo;
