import React from "react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PDFDocument from "../planModal/pdfDocument/PDFDocument";
import "./PlanModal.css";

const PlanModal = ({ plan, selectedPlan  }) => {
  return (
    <div>
      <div
        className="modal fade"
        id="PlanModal"
        tabIndex="-1"
        aria-labelledby="PlanModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title" id="PlanModalLabel">
                <h4>
                  Plan:
                  <span> {selectedPlan.name}</span>
                </h4>
                <h3>
                  Características
                </h3>
              </div>
            </div>
            {plan && (
              <div className="modal-body">
                <div className="container">
                  <h5 className="mb-4">
                  
                  </h5>
                  {plan.details.map((detail, index) => (
                    <div className="row" key={index}>
                      <div className="col">
                        <table className="modal-table">
                          <thead className="modal-table-title">
                            <tr>
                              <th colSpan="2">{detail.title}</th>
                            </tr>
                          </thead>
                          <tbody className="modal-table-info">
                            {detail.info.map((info, i) => (
                              <React.Fragment key={i}>
                                <tr>
                                  <td>{info.info1}</td>
                                </tr>
                                <tr>
                                  <td>{info.info2}</td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="modal-footer">
              <PDFDownloadLink
                document={
                  <PDFDocument plan={plan} selectedPlan={selectedPlan} />
                }
                fileName="Plan.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Generando PDF..."
                  ) : (
                    <button className="button-modal">
                      Descargar<i className="bi bi-box-arrow-down"></i>
                    </button>
                  )
                }
              </PDFDownloadLink>
              <button
                type="button"
                className="button-modal"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
              {/* <PDFViewer>
              <PDFDocument plan={plan} selectedPlan={selectedPlan} />
              </PDFViewer> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanModal;
