import React from "react";

function AboutApiEmail() {
  return (
    <div id="AboutApiEmail">
      <section id="faq" className="faq">
        <div className="container">
          <div className="row">
            <div className="negocio-title-left">
            <h3>ENVIOS DE CORREOS <span>ELECTRONICOS</span></h3>
            </div>
            <div className="container-fluid" data-aos="fade-up">
              <div className="row gy-4">
                <div className="col-lg-7 d-flex flex-column align-items-stretch  order-2 order-lg-1">
                  <div className="accordion accordion-flush px-xl-4">
                    <div
                      className="accordion-item mb-3"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-content-1"
                        >
                          <h3>
                            <i className="bi bi-bell question-icon"></i>
                            Envíos masivos de Correos Electrónicos
                          </h3>
                        </button>
                      </div>

                      <div
                        id="faq-content-1"
                        className="accordion-collapse collapse"
                        data-bs-parent="#faqlist"
                      >
                        <div className="accordion-body">
                          <p>
                            Alcanza a miles de destinatarios con nuestros
                            envíos masivos de correos electrónicos. Personaliza
                            tus mensajes y promociones, y llega a una amplia
                            audiencia con un solo clic.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <h3 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-content-2"
                        >
                          <h3>
                            <i className="bi bi-bell question-icon"></i>
                            Envíos transaccionales de Correos Electrónicos
                          </h3>
                        </button>
                      </h3>
                      <div
                        id="faq-content-2"
                        className="accordion-collapse collapse"
                        data-bs-parent="#faqlist"
                      >
                        <div className="accordion-body">
                          <p>
                            Mantén a tus clientes informados y comprometidos
                            con nuestros envíos transaccionales de correos
                            electrónicos. Entrega confirmaciones de pedidos,
                            facturas y notificaciones importantes de manera
                            automática y personalizada.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 align-items-stretch order-1 order-lg-2 imgemail"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutApiEmail;
