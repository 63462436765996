import React, { useState, useEffect } from "react";
import "./Blog.css";
import { Link } from "react-router-dom";
import Menu from "../../components/menu/Menu";
import Footer from "../../components/footer/Footer";
import img_bl from "../../assets/img-blog/blog-5.jpg";
import img_b2 from "../../assets/img-blog/blog-2.jpg";
import img_b3 from "../../assets/img-blog/blog-3.jpg";

function Blog() {
  const [news] = useState([
    {
      id: 1,
      title:
        "La herramienta imprescindible para impulsar tu estrategia digital",
      content:
        "En la era digital, el email marketing se ha convertido en una poderosa herramienta para las empresas que desean alcanzar a su audiencia de manera efectiva y rentable...",
      src: img_bl,
      alt: "Notice 1",
      categories: "Email Marketing",
      public: "Junio 23, 2023",
      name: "Dicheell Duran",
      link: "/BlogDetails1",
      details: [
        "email marketing",
        "estrategia digital",
        "empresas",
        "audiencia",
      ],
    },
    {
      id: 2,
      title: "Llega a tus clientes de forma directa y efectiva",
      content:
        "En el mundo de la comunicación digital, el SMS marketing ha demostrado ser una estrategia poderosa para llegar a tus clientes de manera directa y efectiva...",
      src: img_b2,
      alt: "Notice 2",
      categories: "SMS Marketing",
      public: "Abril 15, 2023",
      name: "Jorge Vergara",
      link: "/BlogDetails2",
      details: [
        "SMS marketing",
        "estrategia",
        "comunicación digital",
        "efectiva",
      ],
    },
    {
      id: 3,
      title: "La clave para mantener a tus usuarios comprometidos",
      content:
        "En un mundo cada vez más móvil, las notificaciones push se han convertido en una herramienta imprescindible para mantener a tus usuarios comprometidos con tu aplicación o sitio web. ",
      src: img_b3,
      alt: "Notice 3",
      categories: "Notificaciones Push",
      public: "Marzo 22, 2022",
      name: "Jorge Vergara",
      link: "/BlogDetails3",
      details: [
        "notificaciones push",
        "usuarios",
        "aplicación",
        "comprometidos",
      ],
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;
  const totalPages = Math.ceil(news.length / itemsPerPage);
  const [selectedCategory, setSelectedCategory] = useState("Todos");
  const [filteredNews, setFilteredNews] = useState(news);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  };

  function getUniqueCategories() {
    const uniqueCategories = [];
    news.forEach((item) => {
      const existingCategory = uniqueCategories.find(
        (category) => category.name === item.categories
      );
      if (existingCategory) {
        existingCategory.count += 1;
      } else {
        uniqueCategories.push({
          name: item.categories,
          count: 1,
        });
      }
    });
    return uniqueCategories;
  }

  const allCategoryCount = news.length;
  const categories = [
    { name: "Todos", count: allCategoryCount },
    ...getUniqueCategories(),
  ];

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);

  useEffect(() => {
    const filteredNews =
      selectedCategory === "Todos"
        ? news
        : news.filter((item) => item.categories === selectedCategory);
    setFilteredNews(filteredNews);
  }, [selectedCategory, news]);

  
  useEffect(() => {
    setSelectedCategory("Todos");
  }, []);


  // seccion tags
  const [selectedTag, setSelectedTag] = useState("");

  const handleTagClick = (tag) => {
    if (selectedTag === tag) {
      setSelectedTag("");
      removeHighlight();
    } else {
      removeHighlight();
      setSelectedTag(tag);
      highlightTag(tag);
    }
  };

  const highlightTag = (tag) => {
    const titleElements = document.querySelectorAll(".title");
    const contentElements = document.querySelectorAll(".content");

    titleElements.forEach((element) => {
      const html = element.innerHTML;
      const highlightedHtml = html.replace(
        new RegExp(`\\b${tag}\\b`, "gi"),
        `<span class="highlighted">${tag}</span>`
      );
      element.innerHTML = highlightedHtml;
    });

    contentElements.forEach((element) => {
      const html = element.innerHTML;
      const highlightedHtml = html.replace(
        new RegExp(`\\b${tag}\\b`, "gi"),
        `<span class="highlighted">${tag}</span>`
      );
      element.innerHTML = highlightedHtml;
    });

    const tagElements = document.querySelectorAll(".tag");
    tagElements.forEach((element) => {
      if (element.textContent === tag) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  };

  const removeHighlight = () => {
    const highlightedElements = document.querySelectorAll(".highlighted");
    highlightedElements.forEach((element) => {
      element.outerHTML = element.innerHTML;
    });

    const activeTagElement = document.querySelector(".tag.active");
    if (activeTagElement) {
      activeTagElement.classList.remove("active");
    }
  };
 // fin seccion tags

  // Página siempre en al principio
  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);
  // fin de Página siempre en al principio

  return (
    <div id="section_blog">
      <section id="menu">
        <Menu />
      </section>
      <section id="titulo" className="titulo_blog">
        <div className="container">
          <div className="section-title-blog">
            <h3>
              NUESTRO <span>BLOG</span>
            </h3>
            <p>
              Todo sobre cómo hacer que los correos electrónicos sean geniales.
            </p>
          </div>
        </div>
      </section>
      <div id="blog" className="blog">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-8">
              <div className="row gy-4 posts-list">
                {filteredNews
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((item) => (
                    <article key={item.id} className="d-flex flex-column">
                      <div className="post-img">
                        <img
                          src={item.src}
                          alt={`Notice ${item.id}`}
                          className="img-fluid"
                        />
                      </div>
                      <p className="meta-top">{item.categories}</p>
                      <h2 className="title">
                        <Link to={item.link}>{item.title}</Link>
                      </h2>

                      <div className="meta-top">
                        <ul>
                          <li className="d-flex align-items-center">
                            <i className="bi bi-person"></i>{" "}
                            <a href="#!">{item.name}</a>
                          </li>
                          <li className="d-flex align-items-center">
                            <i className="bi bi-clock"></i>{" "}
                            <Link to={item.link}>
                              <time>{item.public}</time>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="content">
                        <p className="content">{item.content}</p>
                      </div>
                      <div className="read-more mt-auto align-self-end">
                        <Link to={item.link}>Leer más</Link>
                      </div>
                    </article>
                  ))}
              </div>
              <div className="row gy-4 posts-list py-5">
                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-center">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        &laquo;
                      </button>
                    </li>
                    {Array.from({ length: totalPages }).map((_, index) => (
                      <li
                        key={index + 1}
                        className={`page-item ${
                          index + 1 === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                {/*<div className="sidebar-item search-form">
                  <h3>Buscar</h3>
                  <form action="" className="mt-3">
                    <input type="text" />
                    <button type="submit">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>*/}
                <div className="sidebar-item categories">
                  <h3>Categorias</h3>
                  <ul className="list-group mt-3">
                    {categories.map((category) => (
                      <li className="list-group-item" key={category.name}>
                        <Link
                          to={`/blog?category=${encodeURIComponent(
                            category.name
                          )}`}
                          onClick={() => handleCategoryChange(category.name)}
                        >
                          {category.name} <span>({category.count})</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="sidebar-item recent-posts">
                  <h3>Post Recientes</h3>
                  <div className="mt-3">
                    <ul className="list-group">
                      {news.slice(-5).map((item) => (
                        <li className="list-group-item" key={item.id}>
                          <div className="post-item mt-3">
                            <img
                              src={item.src}
                              alt=""
                              className="flex-shrink-0"
                            />
                            <div>
                              <h4>
                                <Link to={item.link}>{item.title}</Link>
                              </h4>
                              <time>{item.public}</time>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="sidebar-item tags">
                  <h3>Tags</h3>
                  <ul className="mt-3">
                  {news
            .flatMap((item) => item.details)
            .map((tag) => (
              <li key={tag}>
                <a
                  href="#!"
                  className={selectedTag === tag ? "selected" : ""}
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5"></div>
      <section id="notice_blog" className="notice">
        <div className="notices">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h1 className="mb-4">SUSCRIBETE A NUESTRAS NOTICIAS</h1>
              </div>
              <div className="col-lg-6 button_blog">
                <form
                  action="mailto:radar@radar.com"
                  method="post"
                  encType="text/plain"
                >
                  <div className="input-group mb-3">
                    <input
                      type="email"
                      id="email"
                      className="form-control btn-button-input"
                      placeholder="Ingresa tu correo electrónico"
                      aria-label="Ingresa tu correo electrónico"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn-button btn btn-outline-secondary"
                      >
                        Suscribir
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_blog">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
}

export default Blog;
