import React from "react";
import "./Fidelidad.css";
import Img_ptl from "../../../assets/img_clients/fidelity-img.svg";

const Fidelidad = () => {
  const data = {
    clientesFelices: "126",
    proyectos: "485",
    horasSoporte: "1000",
  };


  return (
    <div id="fidelidad">
      <section id="fidelity-counter" className="fidelity-counter">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4 align-items-center">
            <div className="col-lg-6">
              <img src={Img_ptl} alt="" className="img-fluid" />
            </div>

            <div className="col-lg-6">
              <div className="fidelity-item d-flex align-items-center">
                <h3>{data.clientesFelices}</h3>
                <p>
                  <strong>Clientes Felices</strong> descripcion
                </p>
              </div>
              <div className="fidelity-item d-flex align-items-center">
                <h3>{data.proyectos}</h3>
                <p>
                  <strong>Proyectos</strong> descripcion
                </p>
              </div>
              <div className="fidelity-item d-flex align-items-center">
                <h3>{data.horasSoporte}</h3>
                <p>
                  <strong>Horas de Soporte</strong> descripcion
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Fidelidad;
