import React, { useState } from "react";
import "./AboutApi.css";
import AboutApiPush from "./AboutApiPush";
import AboutApiEmail from "./AboutApiEmail";
import AboutApiSms from "./AboutApiSms";

const AboutApi = () => {
  const [isOpen, setIsOpen] = useState("");

  const toggleSection = (section) => {
    if (isOpen === section) {
      setIsOpen("");
    } else {
      setIsOpen(section);
    }
  };

  return (
    <div id="section1">
      <section id="negocio" className="negocio">
        <div className="portfolio-container">
          <div className="col-lg-12 portfolio-item filter-app">
            <div className="container-fluid" data-aos="fade-up">
              <div className="negocio-title">
                <h3>
                Integración de<br /> Aplicaciones <span>(API's)</span>
                </h3>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <button
                    onClick={() => toggleSection("section3")}
                    aria-controls="section3"
                    aria-expanded={isOpen === "section3"}
                    variant="primary"
                    className="button"
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <i className="bi bi-bell-fill"></i>
                      </div>
                      <h4>
                        <a href="#!">Notificaciones Push</a>
                      </h4>
                      <p>
                        ¡Deja una huella duradera en la mente de tus usuarios
                        con nuestras notificaciones push, mantén a tus clientes
                        siempre conectados y comprometidos!.
                      </p>
                      <div className="celda">
                        <i className="bi bi-arrow-down-circle-fill link"> Ver Más
                        </i>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-4 col-md-4">
                  <button
                    onClick={() => toggleSection("section4")}
                    aria-controls="section4"
                    aria-expanded={isOpen === "section4"}
                    variant="primary"
                    className="button"
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <i className="bi bi-phone-fill"></i>
                      </div>
                      <h4>
                        <a href="#!">Mensajes de Texto</a>
                      </h4>
                      <p>
                        ¡Transmite tu mensaje de forma instantánea y directa con
                        nuestro servicio de mensajes de texto. Llega a tus
                        clientes, socios o empleados de manera inmediata!.
                      </p>
                      <div className="celda">
                        <i className="bi bi-arrow-down-circle-fill link"> Ver Más
                        </i>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-lg-4 col-md-4">
                  <button
                    onClick={() => toggleSection("section5")}
                    aria-controls="section5"
                    aria-expanded={isOpen === "section5"}
                    variant="primary"
                    className="button"
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <i className="bi bi-envelope-fill"></i>
                      </div>
                      <h4>
                        <a href="#!">Envío de Correos Electrónicos</a>
                      </h4>
                      <p>
                        ¡Impulsa tus comunicaciones con diseños atractivos,
                        contenido personalizado y seguimiento en tiempo real!.
                      </p>
                      <div className="celda">
                        <i className="bi bi-arrow-down-circle-fill link"> Ver Más
                        </i>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              {isOpen === "section3" && <AboutApiPush />}
              {isOpen === "section4" && <AboutApiSms />}
              {isOpen === "section5" && <AboutApiEmail />}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutApi;
