import React from "react";

function AboutApiPush() {
  return (
    <div id="AboutApiPush">
      <div id="faq" className="faq">
        <div className="container">
          <div className="row">
            <div className="negocio-title-left">
              <h3>
                NOTIFICACIONES <span>PUSH</span>
              </h3>
            </div>
            <div className="container-fluid" data-aos="fade-up">
              <div className="row gy-4">
                <div className="col-lg-7 d-flex flex-column align-items-stretch  order-2 order-lg-1">
                  <div className="accordion accordion-flush">
                    <div
                      className="accordion-item mb-3"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-content-1"
                        >
                          <h3>
                            <i className="bi bi-bell-fill question-icon"></i>
                            Envíos masivos de notificaciones Push
                          </h3>
                        </button>
                      </div>

                      <div
                        id="faq-content-1"
                        className="accordion-collapse collapse"
                        data-bs-parent="#faqlist"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>
                              <div>
                                <p>
                                  Llega a una amplia audiencia al instante con
                                  nuestros envíos masivos de notificaciones
                                  push. Envía actualizaciones, promociones o
                                  noticias relevantes a todos tus usuarios en
                                  tiempo real y de manera personalizada.
                                </p>
                              </div>
                            </li>
                            <li>
                              <div>
                                <p>
                                  Aprovecha el poder de las notificaciones push
                                  para alcanzar a miles de usuarios al mismo
                                  tiempo. Con nuestros envíos masivos, puedes
                                  mantener a tu audiencia informada y
                                  comprometida con un solo clic.
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <h3 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-content-2"
                        >
                          <h3>
                            <i className="bi bi-bell-fill question-icon"></i>
                            Envíos transaccionales de notificaciones Push
                          </h3>
                        </button>
                      </h3>
                      <div
                        id="faq-content-2"
                        className="accordion-collapse collapse"
                        data-bs-parent="#faqlist"
                      >
                        <div className="accordion-body">
                          <ul>
                            <li>
                              <div>
                                <p>
                                  Crea una experiencia de usuario fluida y
                                  personalizada con nuestros envíos
                                  transaccionales de notificaciones push.
                                  Entrega confirmaciones de transacciones,
                                  actualizaciones de cuenta o mensajes
                                  importantes de forma instantánea y precisa.
                                </p>
                              </div>
                            </li>
                            <li>
                              <div>
                                <p>
                                  Asegúrate de que tus usuarios reciban
                                  notificaciones relevantes en tiempo real. Con
                                  nuestros envíos transaccionales de
                                  notificaciones push, puedes brindar
                                  información crucial y mantener una
                                  comunicación efectiva con tus clientes.
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 align-items-stretch order-1 order-lg-2 imgpush"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutApiPush;
