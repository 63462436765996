import React, { useRef, useEffect, useState  } from "react";
import "./BlogDetails.css";
import { Link } from "react-router-dom";
import Menu from "../../../components/menu/Menu";
import Footer from "../../../components/footer/Footer";
import img_bd3 from "../../../assets/img-blog/blog-2.jpg";
import img_bd4 from "../../../assets/img-blog/blog-recent-2.jpg";
import img_bda1 from "../../../assets/img-blog/blog-author.jpg";

function BlogDetails2() {
  const headings = [
    {
      id: "2",
      image1: img_bd3,
      image2: img_bd4,
      title: "Llega a tus clientes de forma directa y efectiva",
      public: "26/06/2023",
      subtitle1: "Introducción",
      description1:
        "En el mundo de la comunicación digital, el SMS marketing ha demostrado ser una estrategia poderosa para llegar a tus clientes de manera directa y efectiva. En este artículo, exploraremos cómo el SMS marketing puede impulsar tus esfuerzos de marketing y ayudarte a alcanzar tus objetivos comerciales. Descubre cómo utilizar esta táctica para conectar con tu audiencia de forma inmediata y generar resultados impactantes.",
      subtitle2: " Importancia del del SMS marketing en la era móvil",
      description2:
        "En un mundo donde los dispositivos móviles son omnipresentes, el SMS marketing se ha convertido en una herramienta esencial para las empresas. Exploraremos cómo el uso masivo de los teléfonos inteligentes brinda la oportunidad de llegar a los clientes de manera inmediata y personalizada a través de mensajes de texto. Además, destacaremos la alta tasa de apertura y respuesta de los SMS, lo que lo convierte en una estrategia altamente efectiva.",
      subtitle3: "Segmentación y personalización en el SMS marketing",
      description3:
        "La segmentación y personalización son elementos clave para el éxito del SMS marketing. Analizaremos cómo utilizar datos demográficos y de comportamiento para segmentar tu audiencia y enviar mensajes relevantes y personalizados. Además, exploraremos cómo aprovechar la automatización para enviar mensajes en momentos estratégicos y establecer una comunicación continua con tus clientes.",
      subtitle4: "Diseño y contenido efectivo para tus mensajes SMS",
      description4:
        "El diseño y contenido de tus mensajes SMS son fundamentales para captar la atención de tus destinatarios. Discutiremos cómo mantener tus mensajes concisos, claros y llamativos, utilizando un lenguaje persuasivo y llamadas a la acción efectivas. También abordaremos la importancia de la inclusión de enlaces cortos y la optimización para dispositivos móviles.",
      subtitle5:
        "Cumplimiento normativo y buenas prácticas en el SMS marketing",
      description5:
        "Es fundamental cumplir con las regulaciones y buenas prácticas en el SMS marketing. Hablaremos sobre la importancia de obtener el consentimiento de los destinatarios y brindar opciones de exclusión. Además, destacaremos la necesidad de enviar mensajes relevantes y no excesivos, respetando la privacidad y confianza de tus clientes.",
      conclusion:
        "El SMS marketing ofrece una forma directa y efectiva de comunicarte con tus clientes en la era móvil. Aprovecha su capacidad de llegar a tu audiencia de manera inmediata y personalizada, utilizando estrategias de segmentación, diseño efectivo y cumplimiento normativo. Con el SMS marketing adecuado, podrás impulsar tu estrategia de marketing, alcanzar tus objetivos comerciales y fortalecer el vínculo con tus clientes.",
      details: [
        "SMS marketing",
        "estrategia",
        " directa",
        "efectiva",
        "objetivos comerciales",
        "dispositivos móviles",
        "mensajes de texto",
        " alta tasa de apertura",
        "alta tasa de respuesta",
        "segmentación",
        " personalización",
        " datos demográficos",
        "automatización",
        "comunicación continua",
        "diseño",
        "contenido",
        "conciso",
        "llamativo",
        "enlaces cortos",
        "optimización para dispositivos móviles",
        "llamadas a la acción",
        "cumplimiento normativo",
        "consentimiento",
        "opciones de exclusión",
        " mensajes relevantes",
        "privacidad",
        "confianza",
      ],
      autor: "Dicheell Duran",
      autorimage: img_bda1,
      autorInstagram: "https://www.instagram.com/",
      autorFacebook: "https://es-la.facebook.com/",
      autorTwitter: "https://twitter.com/?lang=es",
      autorDescription: "Programador de Página Web",
    },
  ];

  const scrollToRef = useRef([]);

  
  // seccion tags
  const [selectedTag, setSelectedTag] = useState("");

  const handleTagClick = (tag) => {
    if (selectedTag === tag) {
      setSelectedTag("");
      removeHighlight();
    } else {
      removeHighlight();
      setSelectedTag(tag);
      highlightTag(tag);
    }
  };

  const highlightTag = (tag) => {
    const contentElements = document.querySelectorAll(".content p");

    contentElements.forEach((element) => {
      const html = element.innerHTML;
      const highlightedHtml = html.replace(
        new RegExp(`\\b${tag}\\b`, "gi"),
        `<span class="highlighted">${tag}</span>`
      );
      element.innerHTML = highlightedHtml;
    });

    const tagElements = document.querySelectorAll(".sidebar-item.tags li a");
    tagElements.forEach((element) => {
      if (element.textContent === tag) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  };

  const removeHighlight = () => {
    const highlightedElements = document.querySelectorAll(
      ".content p .highlighted"
    );
    highlightedElements.forEach((element) => {
      element.outerHTML = element.innerHTML;
    });

    const activeTagElement = document.querySelector(
      ".sidebar-item.tags li a.active"
    );
    if (activeTagElement) {
      activeTagElement.classList.remove("active");
    }
  };
  // fin seccion tags

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);

  return (
    <div id="section_blog">
      <section id="menu">
        <Menu />
      </section>
      <section id="blog" className="blog">
        <div className="container">
          <div className="row g-5">
            {headings.map((heading, index) => (
              <div className="col-lg-12" key={heading.id}>
                <article
                  key={heading.id}
                  id={`section-${heading.id}`}
                  className="blog-details"
                >
                  <div className="post-img">
                    <img src={heading.image1} alt="blog" className="img-fluid" />
                  </div>
                  <div className="title">
                    <h2>{heading.title}</h2>
                  </div>
                  <div className="meta-top">
                    <ul>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-person"></i>{" "}
                        <a href="#!">{heading.public}</a>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-clock"></i>{" "}
                        <a href="#!">
                          <time>Publicado el {heading.public}</time>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="content"
                    ref={(el) => (scrollToRef.current[index] = el)}
                  >
                    <h3 id="subtitle1">{heading.subtitle1}</h3>
                    <p>{heading.description1}</p>
                    <h3 id="subtitle2">{heading.subtitle2}</h3>
                    <p>{heading.description2}</p>
                    <blockquote>
                      <p>{heading.conclusion}</p>
                    </blockquote>
                    <h3 id="subtitle3">{heading.subtitle3}</h3>
                    <p>{heading.description3} </p>
                    <h3 id="subtitle4">{heading.subtitle4}</h3>
                    <p>{heading.description4}</p>
                    <img src={heading.image2} className="img-fluid" alt="blog" />
                  
                    <h3 id="subtitle5">{heading.subtitle5}</h3>
                    <p>{heading.description5}</p>
                    {headings.map((heading, index) => (
                      <div className="meta-bottom" key={heading.id}>
                        <div className="sidebar-item tags">
                          <ul>
                            {headings.map((heading, index) => (
                              <div className="meta-bottom" key={heading.id}>
                                <div className="sidebar-item tags">
                                  <ul>
                                    {heading.details.map((tag) => (
                                      <li key={tag}>
                                        <a
                                          href="#!"
                                          className={
                                            selectedTag === tag ? "active" : ""
                                          }
                                          onClick={() => handleTagClick(tag)}
                                        >
                                          {tag}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div><div className="table-button">
            <div className="button d-flex justify-content-end">
              <Link to="/Blog">
                <p>VOLVER</p>
              </Link>
            </div>
          </div>
                </article>
               {/*<div className="post-author d-flex align-items-center">
                  <img
                    src={heading.autorimage}
                    className="rounded-circle flex-shrink-0"
                    alt=""
                  />
                  <div>
                    <h4>{heading.autor}</h4>
                    <div className="social-links">
                      <a href={heading.autorTwitter}>
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href={heading.autorFacebook}>
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href={heading.autorInstagram}>
                        <i className="biu bi-instagram"></i>
                      </a>
                    </div>
                    <p>{heading.autorDescription}</p>
                  </div>
                </div> */} 
              </div>
            ))}
            {/*
            <div className="col-lg-3 ">
              <div className="table-top">
                <div className="table">
                  <h3>Tabla de Contenido</h3>
                  {headings.map((heading, index) => (
                    <ul className="list-group mt-3">
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 1}.{heading.subtitle1}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 2}.{heading.subtitle2}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 3}.{heading.subtitle3}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 4}.{heading.subtitle4}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 5}. {heading.subtitle5}
                      </li>
                    </ul>
                  ))}
                  
                </div>
              </div>
            </div>
             */}
          </div>
          
        </div>
      </section>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_blog">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
}

export default BlogDetails2;