import React, { useState, useEffect, useMemo } from "react";
import "./Banner.css";
import img_sl4 from "../../assets/img_carousel/editor.png";
import img_sl from "../../assets/img_carousel/sms_.png";
import img_sl2 from "../../assets/img_carousel/PRUEBA.png";
import img_sl3 from "../../assets/img_carousel/socio.png";

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentImage, setCurrentImage] = useState([]);

  const images = useMemo(
    () => [
      {
        src: img_sl,
        caption: "Envia campañas Masivas",
        caption1: "o Selectivas de",
        caption2: "SMS o Email.",
        description:
          "Llega a tus clientes de una manera rápida y personalizada.",
          button: "¿Comenzamos?",
      },
      {
        src: img_sl2,
        caption: "Más de 1350 plantillas",
        caption1: "de correo electrónico",
        caption2: "HTML gratuitas.",
        description: " tus clientes de una manera rápida y personalizada",
        button: "¿Comenzamos?",
      },
      {
        src: img_sl3,
        caption: "API de correo electrónico",
        caption1: "y las campañas de",
        caption2: "Marketing.",
        description: "Llega a tus clientes de una manera rápida.",
        button: "¿Comenzamos?",
      },
      {
        src: img_sl4,
        caption: "Un Editor",
        caption1: "que ofrece",
        caption2: "multiples recursos.",
        description: "RadarSystems cuenta con un editor de contenido para la comodidad y libertad de nuestros usuarios.",
        button: "¿Comenzamos?",
      },
    ],
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentSlide, images.length]);

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    setCurrentImage([images[currentSlide]]);
  }, [currentSlide, images]);

  return (
    <div id="section-banner">
      <section className="d-flex align-items-center">
        <div className="container-fluid">
          <div className="slider-container">
            <div className="slider">
              {currentImage.map((image, index) => (
                <div
                  className={`slide ${index === currentSlide ? "active" : ""}`}
                  key={index}
                >
                  <img src={image.src} alt={`Slide ${index}`} />
                  <div className="caption d-flex flex-column">
                    <div className="title">
                      <h3>{image.caption}
                      <span> {image.caption1}</span>
                      <strong> {image.caption2}</strong></h3>
                      <p>{image.description}</p>
                    </div>
                    <div className="col-lg-8 button_banner">
                      <form
                        action="mailto:radar@radar.com"
                        method="post"
                        encType="text/plain"
                      >
                        <div className="input-group mb-3">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control btn-button-input"
                            placeholder="Correo electrónico"
                            aria-label="Correo electrónico"
                          />
                          <div className="input-group-append">
                            <button
                              type="submit"
                              className="btn-button btn btn-outline-secondary"
                            >
                              {image.button}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="controls">
              {images.map((_, index) => (
                <div
                  className={`control ${
                    index === currentSlide ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
