import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Resources.css";
import Menu from "../../components/menu/Menu";
import Footer from "../../components/footer/Footer";
import resource1 from "../../assets/img_resource/resource-1.png";
import resource2 from "../../assets/img_resource/resource-7.svg";
import resource3 from "../../assets/img_resource/resource-3.png";
import resource4 from "../../assets/img_resource/resource-4.png";
import resource5 from "../../assets/img_resource/resource-5.png";
import resource6 from "../../assets/img_resource/resourse-6.jpg";
import resource7 from "../../assets/img_resource/resource-8.png";
import resourcecontact from "../../assets/img_resource/resource-contact.jpg";

function Resources() {
  useEffect(() => {
    window.scrollTo("0", "0");
  }, []); 

  return (
    <div id="section_recursos">
      <section id="menu">
        <Menu />
      </section>
      <section id="titulo" className="titulo_recursos">
        <div className="container">
          <div className="section-title-recursos">
            <h3>
              NUESTROS<span> RECURSOS</span>
            </h3>
            <p>
              Transforma tus campañas en oportunidades con herramientas de
              marketing intuitivas y fáciles de usar.
            </p>
          </div>
        </div>
      </section>
      <div className="recursos_varios">
        <div className="container">
          <div className="row recurs">
            <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              <div className="img">
                <img src={resource1} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last ">
              <h3 className="mb-3">
                Integrador de aplicaciones{" "}
                <span className="colour"> Medusa</span>
              </h3>
              <p>
                Gestiona e integra tus aplicaciones preferidas para tener un
                mayor alcance y efectividad en en Marketing Digital.
              </p>
              <div className="d-flex ">
                <Link
                  to={"https://www.radarsystems.net:8080/admin/login.php"}
                  className="btn-get-started"
                >
                  Empezar YA
                </Link>
              </div>
            </div>
          </div>
          <div className="row recurs">
            <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last ">
              <h3 className="mb-3">
                Interactúa con tu audiencia gracias a la automatización
              </h3>
              <p>
                Mantén el contacto con tus clientes con mensajes de bienvenida o
                emails de carritos abandonados. Automatiza campañas para
                alcanzar tus objetivos.
              </p>
              <div className="d-flex ">
                <Link
                  to={"https://www.radarsystems.net:8080/admin/login.php"}
                  className="btn-get-started"
                >
                  Empezar YA
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              <div className="img">
                <img src={resource2} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
          <div className="row recurs">
            <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              <div className="img">
                <img src={resource3} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last ">
              <h3 className="mb-3">Roles y Permisos</h3>
              <p>
                Con nuestro modulo de roles y permisos puedes administrar el
                nivel de permisologias para tus sub-usuarios.
              </p>
              <div className="d-flex ">
                <Link
                  to={"https://www.radarsystems.net:8080/admin/login.php"}
                  className="btn-get-started"
                >
                  Empezar YA
                </Link>
              </div>
            </div>
          </div>
          <div className="row recurs">
            <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last ">
              <h3 className="mb-3">Gestión de Empresas</h3>
              <p>
                Si eres community manager, agencia de marketing digital o
                simplemente administras varios clientes, en este panel de
                gestion de empresas puedes agregarlas y gestionarlas
                individualmente.
              </p>
              <div className="d-flex ">
                <Link
                  to={"https://www.radarsystems.net:8080/admin/login.php"}
                  className="btn-get-started"
                >
                  Empezar YA
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              <div className="img">
                <img src={resource4} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
          <div className="row recurs">
            <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              <div className="img">
                <img src={resource5} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last ">
              <h3 className="mb-3">Crea un contenido de Calidad</h3>
              <p>
                Tu contenido te permitirá dominar los corazones de los lectores.
                Por lo tanto, proporcione contenido de calidad a través de
                correos electrónicos frecuentes, sin necesidad de bombardeos.
              </p>
              <div className="d-flex ">
                <Link
                  to={"https://www.radarsystems.net:8080/admin/login.php"}
                  className="btn-get-started"
                >
                  Empezar YA
                </Link>
              </div>
            </div>
          </div>
          <div className="row recurs">
            <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last ">
              <h3 className="mb-3">Te ofrecemos listas para tus Usuarios</h3>
              <p>
                Puede ser a través de incentivos como Obsequios gratuitos,
                Seminarios Web, Contenido, Cupones o cualquier actualización de
                producto. Asegúrate de que tu intención sea Clara.
              </p>
              <div className="d-flex ">
                <Link
                  to={"https://www.radarsystems.net:8080/admin/login.php"}
                  className="btn-get-started"
                >
                  Empezar YA
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              <div className="img">
                <img src={resource6} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
          <div className="row recurs">
            <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              <div className="img">
                <img src={resource7} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last ">
              <h3 className="mb-3">Analítica Junto Con Segmentación</h3>
              <p>
                Su Tasa De Clics Y La Tasa De Cancelación De Suscripción Son
                Herramientas Para Juzgar La Efectividad De Su Marketing Por
                Correo Electrónico. Además Para Obtener Una Mejor Respuesta.
              </p>
              <div className="d-flex ">
                <Link
                  to={"https://www.radarsystems.net:8080/admin/login.php"}
                  className="btn-get-started"
                >
                  Empezar YA
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="recursos" className="recursos">
        <div className="container" data-aos="zoom-out">
          <div className="row g-5">
            <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
              <h3>Impulsa tus ventas en todos los canales</h3>
              <p>
                Usa mensajes personalizados y segmentación avanzada para
                realizar campañas en todos los canales: email, WhatsApp, SMS y
                mucho más.
              </p>
              <div className="d-flex ">
                <Link to={"/Contacto"} className="btn-get-started">
                  Contáctanos
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
              <div className="img">
                <img src={resourcecontact} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_recursos">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
}

export default Resources;
