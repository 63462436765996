import React, { useRef, useEffect, useState  } from "react";
import "./BlogDetails.css";
import { Link } from "react-router-dom";
import Menu from "../../../components/menu/Menu";
import Footer from "../../../components/footer/Footer";
import img_bd5 from "../../../assets/img-blog/blog-3.jpg";
import img_bd6 from "../../../assets/img-blog/blog-recent-3.jpg";
import img_bda1 from "../../../assets/img-blog/blog-author.jpg";

function BlogDetails3() {
  const headings = [
    {
      id: "3",
      image1: img_bd5,
      image2: img_bd6,
      title: "La clave para mantener a tus usuarios comprometidos",
      public: "26/06/2023",
      subtitle1: "Introducción",
      description1:
        "En un mundo cada vez más móvil, las notificaciones push se han convertido en una herramienta imprescindible para mantener a tus usuarios comprometidos con tu aplicación o sitio web. En este artículo, exploraremos cómo las notificaciones push pueden potenciar tu estrategia de retención de usuarios y generar resultados impactantes. Descubre cómo aprovechar al máximo estas notificaciones para mantener a tus usuarios informados, motivados y activos.",
      subtitle2:
        "Importancia de las notificaciones push en la retención de usuarios",
      description2:
        "Las notificaciones push desempeñan un papel fundamental en la retención de usuarios. Analizaremos cómo estas notificaciones pueden recordar a los usuarios la existencia de tu aplicación o sitio web, mantenerlos actualizados sobre nuevas actualizaciones y funcionalidades, así como recordarles eventos importantes o promociones exclusivas. Esto ayuda a mantener a los usuarios comprometidos y disminuir la posibilidad de desinstalaciones o inactividad.",
      subtitle3: "Personalización y segmentación en las notificaciones push",
      description3:
        "La personalización y segmentación son elementos clave para maximizar el impacto de las notificaciones push. Exploraremos cómo utilizar datos de usuario para enviar notificaciones altamente relevantes y personalizadas. Además, destacaremos la importancia de segmentar tu audiencia según su comportamiento, preferencias o ubicación geográfica para ofrecer mensajes más específicos y adaptados a cada usuario.",
      subtitle4: "Diseño y contenido efectivo en las notificaciones push",
      description4:
        "El diseño y contenido de las notificaciones push juegan un papel crucial en su efectividad. Hablaremos sobre cómo crear mensajes claros, concisos y atractivos, utilizando un lenguaje persuasivo y llamadas a la acción convincentes. También abordaremos la importancia de incluir imágenes o íconos relevantes para captar la atención de los usuarios.",
      subtitle5:
        "Automatización y programación inteligente de notificaciones push",
      description5:
        "La automatización y programación inteligente son aspectos clave en el envío de notificaciones push. Discutiremos cómo aprovechar herramientas y plataformas para automatizar el envío de notificaciones basadas en eventos, comportamientos o cronogramas específicos. Además, exploraremos la importancia de la programación inteligente para enviar notificaciones en el momento óptimo en función de la actividad del usuario.",
      conclusion:
        "Las notificaciones push son una poderosa herramienta para mantener a tus usuarios comprometidos con tu aplicación o sitio web. Aprovecha su capacidad de recordar, informar y motivar a tus usuarios, utilizando estrategias de personalización, diseño efectivo y automatización inteligente. Con las notificaciones push adecuadas, podrás aumentar la retención de usuarios, fortalecer la lealtad.",
      details: [
        "notificaciones push",
        "retención de usuarios",
        "compromiso",
        "informados",
        "motivados",
        "activos",
        "recordatorio",
        "actualizaciones",
        "funcionalidades",
        "eventos importantes",
        "promociones exclusivas",
        "desinstalaciones",
        "inactividad",
        "personalización",
        "segmentación",
        "datos de usuario",
        "relevancia",
        "comportamiento",
        "preferencias",
        "ubicación geográfica",
        "diseño",
        "contenido",
        "claro",
        "conciso",
        "atractivo",
        "lenguaje persuasivo",
        "imágenes",
        "íconos",
        "automatización",
        "programación inteligente",
        "eventos",
        "comportamientos",
        "cronogramas",
        "momento óptimo",
        "actividad del usuario",
      ],
      autor: "Jorge Vergara",
      autorimage: img_bda1,
      autorInstagram: "https://www.instagram.com/",
      autorFacebook: "https://es-la.facebook.com/",
      autorTwitter: "https://twitter.com/?lang=es",
      autorDescription: "Coordinador de Programación",
    },
  ];

  const scrollToRef = useRef([]);

  
  // seccion tags
  const [selectedTag, setSelectedTag] = useState("");

  const handleTagClick = (tag) => {
    if (selectedTag === tag) {
      setSelectedTag("");
      removeHighlight();
    } else {
      removeHighlight();
      setSelectedTag(tag);
      highlightTag(tag);
    }
  };

  const highlightTag = (tag) => {
    const contentElements = document.querySelectorAll(".content p");

    contentElements.forEach((element) => {
      const html = element.innerHTML;
      const highlightedHtml = html.replace(
        new RegExp(`\\b${tag}\\b`, "gi"),
        `<span class="highlighted">${tag}</span>`
      );
      element.innerHTML = highlightedHtml;
    });

    const tagElements = document.querySelectorAll(".sidebar-item.tags li a");
    tagElements.forEach((element) => {
      if (element.textContent === tag) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  };

  const removeHighlight = () => {
    const highlightedElements = document.querySelectorAll(
      ".content p .highlighted"
    );
    highlightedElements.forEach((element) => {
      element.outerHTML = element.innerHTML;
    });

    const activeTagElement = document.querySelector(
      ".sidebar-item.tags li a.active"
    );
    if (activeTagElement) {
      activeTagElement.classList.remove("active");
    }
  };
  // fin seccion tags

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);

  return (
    <div id="section_blog">
      <section id="menu">
        <Menu />
      </section>
      <section id="blog" className="blog">
        <div className="container">
          <div className="row g-5">
            {headings.map((heading, index) => (
              <div className="col-lg-12" key={heading.id}>
                <article
                  key={heading.id}
                  id={`section-${heading.id}`}
                  className="blog-details"
                >
                  <div className="post-img">
                    <img
                      src={heading.image1}
                      alt="blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="title">
                    <h2>{heading.title}</h2>
                  </div>
                  <div className="meta-top">
                    <ul>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-person"></i>{" "}
                        <a href="#!">{heading.public}</a>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-clock"></i>{" "}
                        <a href="#!">
                          <time>Publicado el {heading.public}</time>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="content"
                    ref={(el) => (scrollToRef.current[index] = el)}
                  >
                    <h3 id="subtitle1">{heading.subtitle1}</h3>
                    <p>{heading.description1}</p>
                    <h3 id="subtitle2">{heading.subtitle2}</h3>
                    <p>{heading.description2}</p>
                    <blockquote>
                      <p>{heading.conclusion}</p>
                    </blockquote>
                    <h3 id="subtitle3">{heading.subtitle3}</h3>
                    <p>{heading.description3} </p>
                    <h3 id="subtitle4">{heading.subtitle4}</h3>
                    <p>{heading.description4}</p>
                    <img
                      src={heading.image2}
                      className="img-fluid"
                      alt="blog"
                    />

                    <h3 id="subtitle5">{heading.subtitle5}</h3>
                    <p>{heading.description5}</p>
                    {headings.map((heading, index) => (
                      <div className="meta-bottom" key={heading.id}>
                        <div className="sidebar-item tags">
                          <ul>
                            {headings.map((heading, index) => (
                              <div className="meta-bottom" key={heading.id}>
                                <div className="sidebar-item tags">
                                  <ul>
                                    {heading.details.map((tag) => (
                                      <li key={tag}>
                                        <a
                                          href="#!"
                                          className={
                                            selectedTag === tag ? "active" : ""
                                          }
                                          onClick={() => handleTagClick(tag)}
                                        >
                                          {tag}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="table-button">
                    <div className="button d-flex justify-content-end">
                      <Link to="/Blog">
                        <p>VOLVER</p>
                      </Link>
                    </div>
                  </div>
                </article>
                {/*<div className="post-author d-flex align-items-center">
                  <img
                    src={heading.autorimage}
                    className="rounded-circle flex-shrink-0"
                    alt=""
                  />
                  <div>
                    <h4>{heading.autor}</h4>
                    <div className="social-links">
                      <a href={heading.autorTwitter}>
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href={heading.autorFacebook}>
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href={heading.autorInstagram}>
                        <i className="biu bi-instagram"></i>
                      </a>
                    </div>
                    <p>{heading.autorDescription}</p>
                  </div>
                </div> */}
              </div>
            ))}
            {/*
            <div className="col-lg-3 ">
              <div className="table-top">
                <div className="table">
                  <h3>Tabla de Contenido</h3>
                  {headings.map((heading, index) => (
                    <ul className="list-group mt-3">
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 1}.{heading.subtitle1}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 2}.{heading.subtitle2}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 3}.{heading.subtitle3}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 4}.{heading.subtitle4}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 5}. {heading.subtitle5}
                      </li>
                    </ul>
                  ))}
                  
                </div>
              </div>
            </div>
             */}
          </div>
        </div>
      </section>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_blog">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
}

export default BlogDetails3;
