import React, { useEffect } from "react";
import Menu from "../../components/menu/Menu";
import Footer from "../../components/footer/Footer";
import Form from "./form/Form";
import "./Contact.css";
import contactimg from "../../assets/img_contact/contact.svg";
import contactusa from "../../assets/img_contact/contact_flag_usa.png";
import contactvzla from "../../assets/img_contact/contact_flag_vzla.png";

const Contact = () => {
  const data = {
    dirvzla:
      "Av. Lima Edif. Torre Phelps Los Caobos, Caracas - Distrito Capital",
    imgvzla: contactvzla,
    dirusa: "900 Flora ST, Elizabeth, 07201, United States",
    imgusa: contactusa,
    email: "",
    tlfusa: "+(1) 212-7814413",
    tlfvzla: "+(58) 212-7930266",
    tlfvzla1: "+(58) 212-7932912",
    imgcontact: contactimg,
  };

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);

  return (
    <div id="section_contacto">
      <section id="menu">
        <Menu />
      </section>
      <section id="titulo" className="titulo_contacto">
        <div className="container">
          <div className="section-title-contact">
            <h3>
              NUESTROS <span>CONTACTOS</span>
            </h3>
            <p>Registra tu empresa y comienza a disfrutar del control total.</p>
          </div>
        </div>
      </section>
      <div className="contacto">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="info-box  mb-4">
                <i className="bi bi-phone-fill"></i>
                <h3>Teléfonos</h3>
                <p>
                  <img src={data.imgvzla} className="flag" alt="flag_vzla" />
                  <a href="tel:+58212">{data.tlfvzla}</a> /{" "}
                  <a href="tel:+582">{data.tlfvzla1}</a>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="info-box  mb-4">
                <i className="bi bi-envelope-fill"></i>
                <h3>Email</h3>
                <p>
                  <a href="mailto:ventas@expomarketing.biz">{data.email}</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="info-box mb-4">
              <i className="bi bi-geo-alt-fill"></i>
              <h3>Dirección</h3>
              <p className="mb-4">
                <img src={data.imgvzla} className="flag" alt="flag_vzla" />
                {data.dirvzla}
              </p>
              <p>
                <img src={data.imgusa} className="flag" alt="flag_usa" />
                {data.dirusa}
              </p>
            </div>
          </div>
          <div className="form">
            <div className="row">
              <div className="col-lg-6">
                <img src={data.imgcontact} className="img-call" alt="" />
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <Form />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_contacto">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
};
export default Contact;
