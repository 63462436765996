import React, { useEffect } from "react";import "./Ventas.css";
import Menu from "../../../components/menu/Menu";
import Footer from "../../../components/footer/Footer";


function Ventas() {
  
  useEffect(() => {
    window.scrollTo("0", "0");
  }, []); 
  
  return (
    <div id="section_ventas">
      <section id="menu">
        <Menu />
      </section>
      <section id="titulo">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h3>
            Creación y Gestión de<span> Empresas</span>
            </h3>
            <p>
              Realiza seguimiento de tus clientes potenciales y aumenta tus
              ingresos.
            </p>
          </div>
        </div>
      </section>
      
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_ventas">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
}

export default Ventas;
