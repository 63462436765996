import React, { useEffect }from "react";
import "./Chat.css";
import Menu from "../../../components/menu/Menu";
import Footer from "../../../components/footer/Footer";

function Chat() {

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []); 
  
  return (
    <div id="section_chat">
      <section id="menu">
        <Menu />
      </section>
      <section id="titulo">
        <div className="container">
          <div className="section-title">
            <h2>Servicios</h2>
            <h3>
              Chat<span> Transacional</span>
            </h3>
            <p>
              Conversa con clientes a través de herramientas de chat
              personalizadas desde tu página web
            </p>
          </div>
        </div>
      </section>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_chat">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
}

export default Chat;
