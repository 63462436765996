import React from "react";
import "./Footer.css";
import logo_footer from "../../assets/img_logos_radar/logo_radar.png";
import { Link } from "react-router-dom";
import contactvzla from "../../assets/img_contact/contact_flag_vzla.png";

function Footer() {
  return (
    <div>
      <div id="footer" className="hide">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <Link to="/" className="logo">
                  <img src={logo_footer} alt="Logo_Radar" />
                  <hr />
                </Link>
                <p>
                  RadarSystems es la plataforma integral para crear relaciones
                  con clientes.
                </p>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h2>Enlaces Útiles</h2>
                <ul>
                  <li>
                    <Link to={"/"} className="nav-link scrollto">
                      <i className="bi bi-chevron-right"></i>
                      <p> Documentos</p>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"} className="nav-link scrollto">
                      <i className="bi bi-chevron-right"></i>
                      <p> Apis</p>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/QuestionList"} className="nav-link scrollto">
                      <i className="bi bi-chevron-right"></i>
                      <p> Preguntas Frecuentes</p>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"} className="nav-link scrollto">
                      <i className="bi bi-chevron-right"></i>
                      <p> Ingresar</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h2>Nuestros Servicios</h2>
                <ul>
                  <li>
                    <Link to={"/Servicios"}>
                      <i className="bi bi-chevron-right"></i>
                      <p> Servicios</p>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/PlanesTarifas"}>
                      <i className="bi bi-chevron-right"></i>
                      <p> Planes</p>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/Recursos"}>
                      <i className="bi bi-chevron-right"></i>
                      <p> Recursos</p>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/Blog"}>
                      <i className="bi bi-chevron-right"></i>
                      <p> Blog</p>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/Contacto"}>
                      <i className="bi bi-chevron-right"></i>
                      <p> Contácto</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h2>Ubicación</h2>

                <p>
                  <img src={contactvzla} className="flag" alt="flag_vzla" />
                  Av. Lima Edif. Torre Phelps Los Caobos, Zona Postal 1010{" "}
                  <br />
                  Caracas - Distrito Capital <br />
                </p>
                <h2>Contactos</h2>
                <ul>
                  <li>
                    <a href="ventas@expomarketing.biz" placeholder="Correo">
                      <p>ventas@expomarketing.biz</p>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+582127930266" placeholder="LLamar">
                      <p>+58 212-7930266</p>
                    </a>
                  </li>
                </ul>
              </div>
              {/*<div className="col-lg-3 col-md-6 footer-links">
                <h2>Redes Sociales</h2>
                <p>
                  RadarSystems es la plataforma integral para crear relaciones
                  con clientes.
                </p>
                <div className="social-links mt-2">
                  <a href="#!" className="twitter">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="#!" className="facebook">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="#!" className="instagram">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="#!" className="google-plus">
                    <i className="bi bi-google"></i>
                  </a>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
        <div className="container py-2">
          <div className="copyright">
            &copy; Copyright 1998 - 2021
            <strong>
              <span> RadarSystem.net </span>
            </strong>
            . Todos Los Derechos Reservados.
          </div>
        </div>
      </div>
      <div id="footer" className="hiden">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 footer-link">
                <Link to="/" className="logo">
                  <img src={logo_footer} alt="Logo_Radar" />
                  <hr />
                </Link>
              </div>

              <div className="col-lg-12 footer-links mt-4">
                <p>
                  RadarSystems es la plataforma integral para crear relaciones
                  con clientes.
                </p>
              </div>
              <div className="col-lg-12 footer-links mb-4 footer-contact">
                <h2>Ubicación</h2>
                <p>
                  <img src={contactvzla} className="flag" alt="flag_vzla" />
                  Av. Lima Edif. Torre Phelps Los Caobos, Zona Postal 1010{" "}
                  <br />
                  Caracas - Distrito Capital <br />
                </p>
                <h2>Contactos</h2>
                <ul>
                  <li>
                    <a href="ventas@expomarketing.biz" placeholder="Enviar Email">
                      <p>ventas@expomarketing.biz</p>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+582127930266" placeholder="LLamar">
                      <p>+58 212-793-02-66</p>

                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-2">
          <div className="copyright">
            &copy; Copyright 1998 - 2021
            <strong>
              <span> RadarSystems.net </span>
            </strong>
            . Todos Los Derechos Reservados.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
