
import React, { createContext, useState, useEffect } from "react";


const DeviceContext = createContext();

const DeviceProvider = ({ children }) => {
    const [deviceType, setDeviceType] = useState("");

    useEffect(() => {
        const detectDeviceType = () => {
            if (window.innerWidth <= 1000) {
                setDeviceType("mb");
            } else {
                setDeviceType("dk");
            }
        };

        detectDeviceType();

        window.addEventListener("resize", detectDeviceType);

        return () => {
            window.removeEventListener("resize", detectDeviceType);
        };
    }, []);

    return (
        <DeviceContext.Provider value={{ deviceType }}>
            {children}
        </DeviceContext.Provider>
    );
};

export { DeviceContext, DeviceProvider };
