import React, { useState, useEffect } from "react";
import "./Question.css";
import { Link } from "react-router-dom";

function Question() {
  const [activeAccordion, setActiveAccordion] = useState(0);

  const toggleAccordion = (index) => {
   if (activeAccordion === index) {
    return; 
  }
  setActiveAccordion(index);
};

  const accordions = [
    {
      title: "Email Marketing",
      questions: [
        {
          id:"1",
          question: "¿Cómo puedo aumentar la tasa de apertura de mis correos electrónicos?",
          answer: "Segmentar y validar tu lista de contactos con nuestro servicio de validación de cuentas de correo electrónico, personalizar tus asuntos, crear contenido optimizado y diseñar plantillas HTML llamativas con nuestro editor de plantillas estilo Canva.",
        },
        {
          id:"2",
          question: "¿Cuál es la mejor frecuencia de envío de correos electrónicos para mi audiencia?",
          answer: "La frecuencia óptima varía, prueba y ajusta según la respuesta de tu audiencia. Con nuestro avanzado panel de estadísticas puedes llevar el análisis de tus campañas al siguiente nivel con un detallado sistema estadístico en tiempo real.",
        },
        {
          id:"3",
          question: "¿Cómo puedo mejorar la entrega de mis correos electrónicos en la bandeja de entrada en lugar de la carpeta de spam?",
          answer: "Haciendo las debidas configuraciones previas antes de iniciar el envío de campañas como SPF, DKIM, DMARK, BIMI, registro DNS, entre otros. Utiliza prácticas de autenticación y evita palabras de spam en tus correos. ",
        },
        {
          id:"4",
          question: "¿Cuáles son las mejores prácticas para diseñar un correo electrónico atractivo y efectivo?",
          answer: "Diseña con claridad, brevedad y llamadas a la acción destacadas. En RadarSystems tenemos herramientas que potencian la creación de campañas de email marketing.",
        },
      ],
    },
    {
      title: "Email Transaccional",
      questions: [
        {
          id:"5",
          question: "¿Cuál es la diferencia entre un correo electrónico transaccional y un correo electrónico de marketing?",
          answer: "El transaccional es para comunicaciones individuales, mientras que el marketing es para campañas masivas.",
        },
        {
          id:"6",
          question: "¿Qué información y elementos clave deben incluirse en un correo electrónico transaccional?",
          answer: "Incluye información relevante (facturas, confirmaciones, compras, notificaciones) y personalización cuando sea posible.",
        },
        {
          id:"7",
          question: "¿Cómo puedo personalizar y automatizar los correos electrónicos transaccionales?",
          answer: "Utiliza plantillas y automatización para agilizar el proceso de envío. ",
        },
        {
          id:"8",
          question: "¿Qué medidas de seguridad debo tomar para garantizar la entrega y la integridad de los correos electrónicos transaccionales?",
          answer: "Implementa autenticación y cifrado para proteger la seguridad de los correos.",
        },
      ],
    },
    {
      title: "Sms Marketing",
      questions: [
        {
          id:"9",
          question: "¿Cuál es la efectividad del SMS marketing en comparación con otros canales de marketing?",
          answer: "El SMS tiene una alta tasa de apertura y es efectivo para comunicaciones breves.",
        },
        {
          id:"10",
          question: "¿Cómo puedo obtener y administrar una lista de suscriptores para el SMS marketing?",
          answer: "Utiliza una plataforma de gestión de suscriptores como RadarSystems en la çual puedes crear listas y segmentos de tus contactos. ",
        },
        {
          id:"11",
          question: "¿Cómo puedo personalizar y segmentar los mensajes SMS para aumentar su relevancia y efectividad?",
          answer: "	Segmenta y personaliza tus mensajes para aumentar la relevancia.",
        },
        {
          id:"12",
          question: "¿Cuáles son las mejores prácticas para incluir llamadas a la acción (CTA) efectivas en los mensajes SMS de marketing?",
          answer: "	Utiliza CTAs claras y concisas para impulsar la acción, utiliza nuestro acortador de url Medusa puedes generar una mayor interacción en tus campañas.",
        },
      ],
    },
    {
      title: "Sms Transaccional",
      questions: [
        {
          id:"13",
          question: "¿Qué tipos de mensajes se consideran SMS transaccionales?",
          answer: "Incluye mensajes de confirmación, notificaciones de entrega y actualizaciones de cuenta",
        },
        {
          id:"14",
          question: "¿Cómo puedo integrar y automatizar los mensajes SMS transaccionales en mis sistemas y aplicaciones?",
          answer: "Integra nuestra API para enviar mensajes automáticamente desde tus sistemas y obtén tus estadísticas personalizadas y en tiempo real.",
        },
        {
          id:"5",
          question: "¿Cuál es la importancia de la entrega y la velocidad de los mensajes SMS transaccionales?",
          answer: "La entrega rápida es crucial para la experiencia del usuario y la confiabilidad.",
        },
        {
          id:"16",
          question: "¿Qué medidas de seguridad se deben implementar para proteger la privacidad y la integridad de los mensajes SMS transaccionales?",
          answer: "Utiliza protocolos de seguridad como encriptación y autenticación.",
        },
      ],
    },
    {
      title: "Notificaciones Push Masivas",
      questions: [
        {
          id:"17",
          question: "¿Cómo puedo segmentar a mis usuarios y personalizar los mensajes en notificaciones push masivas?",
          answer: "Segmenta a tus usuarios y personaliza los mensajes según sus preferencias.",
        },
        {
          id:"18",
          question: "¿Qué plataforma puedo utilizar para enviar notificaciones push a gran escala?",
          answer: "Utiliza una plataforma confiable para enviar notificaciones a gran escala como RadarSystems.",
        },
        {
          id:"19",
          question: "¿Cuáles son las regulaciones de privacidad que debo cumplir al enviar notificaciones push masivas?",
          answer: "Asegúrate de cumplir con las regulaciones de privacidad y solicitar el consentimiento del usuario.",
        },
        {
          id:"20",
          question: "¿Cómo puedo crear mensajes efectivos en notificaciones push masivas?",
          answer: "Crea mensajes breves y atractivos que impulsen la interacción.",
        },
      ],
    },
    {
      title: "Notificaciones Push Transaccionales",
      questions: [
        {
          id:"21",
          question: "¿Qué tipo de notificaciones se consideran notificaciones push transaccionales?",
          answer: "Las notificaciones inmediatas para confirmar acciones o informar cambios relevantes.",
        },
        {
          id:"22",
          question: "¿Cómo puedo integrar notificaciones push transaccionales en mi sistema?",
          answer: "Utiliza nuestra API para integrar las notificaciones en tu sistema.",
        },
        {
          id:"23",
          question: "¿Qué características deben tener las notificaciones push transaccionales?",
          answer: "Asegúrate de que las notificaciones sean claras y relevantes para el usuario.",
        },
        {
          id:"24",
          question: "¿Qué medidas de seguridad debo implementar para proteger la integridad de las notificaciones push transaccionales?",
          answer: "Implementa medidas de seguridad para proteger la integridad de las notificaciones.",
        },
      ],
    },
  ];

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);
  
  return (
    <div id="section_questions">
      <section id="questions" className="questions">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-4">
              <div className="content px-xl-2">
                <div className="questions-title">
                  <h3>
                    Preguntas <span>Frecuentes</span>
                  </h3>
                  <p className="py-4">
                  Aquí encontrarás respuestas a las preguntas más frecuentes que nuestros clientes nos han planteado.
                  </p>
                  <div className="d-flex justify-content-left">
                    <Link to={"/QuestionList#section_questions"} className="btn-get-started">
                      Ver más
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="title-accordion">
                {accordions.map((accordion, index) => (
                  <div key={index} className="box-accordion">
                    <button
                      className={`button_questions ${
                        activeAccordion === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {accordion.title}
                    </button>
                  </div>
                ))}
              </div>
              {accordions.map((accordion, index) => (
                <div key={index}>
                  {activeAccordion === index && (
                    <div className="accordion" id={`accordion${index}`}>
                      {accordion.questions.map((question, questionIndex) => (
                        <div className="accordion-item" key={questionIndex}>

                      <h2
                            className="accordion-header"
                            id={`heading${questionIndex}`}
                          >
                         
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${questionIndex}`}
                              aria-expanded="false"
                              aria-controls={`collapse${questionIndex}`}
                            >
                             {question.id}. {question.question}
                            </button>
                          </h2>
                          <div
                            id={`collapse${questionIndex}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading${questionIndex}`}
                            data-bs-parent={`#accordion${index}`}
                          >
                            <div className="accordion-body">
                              {question.answer}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Question;
