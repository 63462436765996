import React, { useContext, useState } from "react";
import "./Menu.css";
import logo from "../../assets/img_logos_radar/banner_horizontal.png";
import Info from "./info/Info";
import "./info/Info.css";
import { Link } from "react-router-dom";
import { DeviceContext } from "../../context/TypeDevice";

function Menu() {
  const { deviceType } = useContext(DeviceContext);
  const [menuMobile, setMenuMobile] = useState("closed-mb");
  const navLinks = document.querySelectorAll('.navbar ul.open-mb li a');

  function openMenuMobile() {
    setMenuMobile(prevState => (prevState === "closed-mb" ? "open-mb" : "closed-mb"));
  }

  navLinks.forEach((link) => {
    link.addEventListener('click', () => {
      link.parentNode.classList.toggle('hide-links');
    });
  });

  return (
    <div className="header fixed-top">
      <Info />
      <div className="header">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <Link to={"/"} className="logo">
            <img src={logo} alt="" />
          </Link>
          <nav id="navbar" className="navbar">
            <ul className={deviceType === "mb" ? menuMobile : ""}>
              <li>
                <Link to={"/"} className="nav-link scrollto">
                  <h2>Inicio</h2>
                </Link>
              </li>
              <li className="dropdown megamenu">
                <Link to={"/Servicios"}>
                  <h2>Servicios </h2>
                </Link>
                <ul className="servicios-submenu">
                  <li>
                    <a className="titulo_submenu_2" href="#!">
                      <p className="bi bi-envelope-fill"> API Masivas</p>
                    </a>
                    <a href="#!">Masivo Push</a>
                    <a href="#!">Masivo SMS</a>
                    <a href="#!">Masivo Email</a>
                  </li>
                  <div className="vr vr-blurry"></div>
                  <li>
                    <a className="titulo_submenu_2" href="#!">
                      <p className="bi bi-inboxes-fill"> API MT</p>
                    </a>
                    <a href="#!">MT Push</a>
                    <a href="#!">MT SMS</a>
                    <a href="#!">MT Email</a>
                  </li>
                  <div className="vr vr-blurry"></div>
                  <li>
                    <a className="titulo_submenu_2" href="#!">
                      <p className="bi bi-inboxes-fill"> Marketing</p>
                    </a>
                    <a href="#!">Masivo SMS</a>
                    <a href="#!">Masivo Email</a>
                    <a href="#!">Masivo</a>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"/PlanesTarifas"} className="nav-link scrollto">
                  <h2>Planes</h2>
                </Link>
              </li>
              <li>
                <Link to={"/Recursos"} className="nav-link scrollto">
                  <h2>Recursos</h2>
                </Link>
              </li>
              <li>
                <Link to={"/Blog"} className="nav-link scrollto">
                  <h2>Blog</h2>
                </Link>
              </li>
              <li>
                <Link to={"/Contacto"} className="nav-link scrollto">

                  <h2>Contacto</h2>
                </Link>
              </li>
              <li>
                <div className="oculto_mobile">Iniciar Sesión</div>
                <Link
                  to={"https://www.radarmailer2.me/radarweb/html/radarsystems2/admin/login.php"} className="nav-link scrollto button-login">
                  <h2>Ingresar</h2>
                </Link>
              </li>
            </ul>
            <i
              className={`bi mobile-nav-toggle ${menuMobile === "open-mb" ? "bi-x" : "bi-list"
                }`}
              onClick={openMenuMobile}
            ></i>

          </nav>
        </div>
      </div>
    </div>
  );
}

export default Menu;
