import React from "react";
import { Link } from "react-router-dom";
import "./About.css";
import Menu from "../../components/menu/Menu";
import Banner from "../../components/banner/Banner";
import AboutInfo from "./aboutinfo/AboutInfo";
import Question from "./questions/Question";
import Footer from "../../components/footer/Footer";
import aboutimg from "../../assets/img_about/about-2.png";

function About() {
  return (
    <div id="section_about">
      <section id="menu-banner">
        <Menu />
        <Banner />
      </section>
      <AboutInfo />
      <section id="negocio" className="negocio">
        <div className="container">
          <div className="negocio-title">
            <h3>
              La Clave del Éxito <br />
              <span>Radar Systems</span>
            </h3>
            <p></p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <img src={aboutimg} className="img-about" alt="about" />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center">
              <h3>
                Conéctate con tu audiencia, impulsa la conversión y establece
                una comunicación sólida.
              </h3>
              <p className="fst-italic">
                Conexión estratégica, Conversión impactante y Comunicación
                efectiva.
              </p>
              <ul>
                <li>
                  <i className="bi bi-stopwatch-fill"></i>
                  <div>
                    <h5>Inmediatez</h5>
                    <p>
                      Envía grandes volúmenes de correos electrónicos de manera
                      eficiente y efectiva.
                    </p>
                  </div>
                </li>
                <li>
                  <i className="bi bi-union"></i>
                  <div>
                    <h5>Integración</h5>
                    <p>
                      Nuestra plataforma se integra sin problemas con
                      aplicaciones y redes sociales, brindándote una experiencia
                      de marketing potente.
                    </p>
                  </div>
                </li>
                <li>
                  <i className="bi bi-book-fill"></i>
                  <div>
                    <h5>Edición</h5>
                    <p>
                      Facilitamos la edición de formatos para crear campañas
                      personalizadas y atractivas adaptandose a tu marca y
                      objetivos.
                    </p>
                  </div>
                </li>
                <li>
                  <i className="bi bi-file-earmark-bar-graph-fill"></i>
                  <div>
                    <h5>Estadísticas</h5>
                    <p>
                      Proporcionamos estadísticas personalizadas en tiempo real
                      para medir el impacto de tus campañas y tomar decisiones
                      informadas.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Question />
      <section id="newsletter" className="newsletter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 columna">
              <div className="title-color">
                <h3>
                  +<span>25</span>
                </h3>
              </div>
              <div className="title-color-2">
                <h3>
                  AÑOS
                  <span> EN EL MERCADO LATINOAMERICANO</span>
                </h3>
                <h6>Miles de clientes nos avalan enviando Email y Sms.</h6>{" "}
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2">
              <div className="d-flex justify-content-center">
                <Link to={"/Contacto"} className="btn-get-started">
                  Contactar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_about">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
}

export default About;
