import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Marketing.css";
import Menu from "../../../components/menu/Menu";
import Footer from "../../../components/footer/Footer";
import Marketing1 from "../../../assets/img-services/marketing-1.svg";
import Marketing2 from "../../../assets/img-services/marketing-2.svg";

function Marketing() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const iconBoxes = [
    {
      id: 1,
      icon: "bi-envelope-fill",
      title: "Email Marketing",
      description:
        "Envía a tus clientes campañas de Email con diseños atractivos.",
      modalContent: (
        <div className="section-title-marketing">
          <h3>
            Email<span> Marketing</span>
          </h3>
          <p>
            Transforma tus campañas en oportunidades con herramientas de
            marketing intuitivas y fáciles de usar.
          </p>
          <img src={Marketing1} alt="Email Marketing" className="img-fluid" />
        </div>
      ),
    },
    {
      id: 2,
      icon: "bi-envelope-fill",
      title: "SMS Marketing",
      description: "Envía mensajes especificos directamente a tus clientes.",
      modalContent: (
        <div className="section-title-marketing">
          <h3>
            SMS<span> Marketing</span>
          </h3>

          <p>Envía mensajes especificos directamente a tus clientes.</p>
          <img src={Marketing2} alt="SMS Marketing" />
        </div>
      ),
    },
    {
      id: 3,
      icon: "bi-envelope-fill",
      title: "Formularios de Suscripción",
      description:
        "Aumenta tu lista de contactos con sencillos y personalizados formularios.",
      modalContent: (
        <div className="section-title-marketing">
          <h3>
            Formularios de <span> Suscripción</span>
          </h3>
          <p>
            Transforma tus campañas en oportunidades con herramientas de
            marketing intuitivas y fáciles de usar.
          </p>
          <img src={Marketing1} alt="Email Marketing" className="img-fluid" />
        </div>
      ),
    },
    {
      id: 4,
      icon: "bi-envelope-fill",
      title: "Landing Pages",
      description: "Convierte visitantes de tu web en potenciales clientes.",
      modalContent: (
        <div className="section-title-marketing">
          <h3>
            Landing <span> Pages</span>
          </h3>
          <p>
            Transforma tus campañas en oportunidades con herramientas de
            marketing intuitivas y fáciles de usar.
          </p>
          <img src={Marketing1} alt="Email Marketing" className="img-fluid" />
        </div>
      ),
    },
    {
      id: 5,
      icon: "bi-envelope-fill",
      title: " SMS Masivo",
      description:
        "Envía a tus clientes campañas de Email con diseños atractivos.",
      modalContent: (
        <div className="section-title-marketing">
          <h3>
            SMS<span> Marketing</span>
          </h3>
          <p>
            Transforma tus campañas en oportunidades con herramientas de
            marketing intuitivas y fáciles de usar.
          </p>
          <img src={Marketing1} alt="Email Marketing" className="img-fluid" />
        </div>
      ),
    },
    {
      id: 6,
      icon: "bi-envelope-fill",
      title: "PUSH Masivo",
      description:
        "Envía a tus clientes campañas de Email con diseños atractivos.",
      modalContent: (
        <div className="section-title-marketing">
          <h3>
            PUSH<span> masivo</span>
          </h3>
          <p>
            Transforma tus campañas en oportunidades con herramientas de
            marketing intuitivas y fáciles de usar.
          </p>
          <img src={Marketing1} alt="Email-Marketing" className="img-fluid" />
        </div>
      ),
    },
  ];

  const openModal = (content) => {
    setModalContent({ ...content, title: content.title });

    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent("");
  };

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);

  return (
    <div id="section_marketing">
      <section id="menu">
        <Menu />
      </section>
      <section id="servicios" className="servicios">
        <div className="container" data-aos="fade-up">
          <div className="section-title-marketing">
            <h3>
              Plataforma<span> Email Marketing</span>
            </h3>
            <p>
              Transforma tus campañas en oportunidades con herramientas de
              marketing intuitivas y fáciles de usar.
            </p>
          </div>
        </div>
      </section>
      <div className="marketing">
        <div className="container" data-aos="fade-up">
          <div className="row">
            {iconBoxes.map((box) => (
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mb-4"
                data-aos="zoom-in"
                data-aos-delay="100"
                key={box.id}
              >
                <div className="icon-box">
                  <div className="icon">
                    <i className={`bi ${box.icon}`}></i>
                  </div>
                  <h4>
                    <button onClick={() => openModal(box.modalContent)}>
                      {box.title}
                    </button>
                  </h4>
                  <p>{box.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="my-4 d-flex justify-content-end">
            <Link to="/Servicios" className="btn-get-started">
              <i className="bi bi-arrow-return-left"></i> VOLVER A SERVICIOS
            </Link>
          </div>
        </div>
      </div>

      <div className="py-4"></div>
      <section id="servicios-letter" className="servicios-letter">
        <div className="servicios-letters">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1>Envía con confianza</h1>
                <h6 className="mb-4">
                  Asóciese con el servicio de correo electrónico en el que
                  confían los desarrolladores y los especialistas en marketing
                  para ahorrar tiempo, escalabilidad y experiencia en la
                  entrega.
                </h6>
                <div className="d-flex justify-content-center">
                  <Link to={"/Contacto"} className="btn-get-started">
                    Contáctanos
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_marketing">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal modal-open" style={{ display: "block" }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">{modalContent}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="button-modal"
                  data-bs-dismiss="modal"
                  onClick={closeModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Marketing;
