import React, { useRef, useEffect, useState } from "react";
import "./BlogDetails.css";
import { Link } from "react-router-dom";
import Menu from "../../../components/menu/Menu";
import Footer from "../../../components/footer/Footer";
import img_bdl from "../../../assets/img-blog/blog-5.jpg";
import img_bd2 from "../../../assets/img-blog/blog-recent-5.jpg";
import img_bda1 from "../../../assets/img-blog/blog-author.jpg";

function BlogDetails1() {
  const headings = [
    {
      id: "1",
      image1: img_bdl,
      image2: img_bd2,
      title:
        "La herramienta imprescindible para impulsar tu estrategia digital",
      public: "27/06/2023",
      subtitle1: "Introducción",
      description1:
        "En la era digital, el email marketing se ha convertido en una poderosa herramienta para las empresas que desean alcanzar a su audiencia de manera efectiva y rentable. En este artículo, exploraremos cómo el email marketing puede potenciar tu estrategia digital y generar resultados tangibles. Descubre cómo aprovechar al máximo esta táctica de marketing y llevar tu negocio al siguiente nivel.",
      subtitle2: "Importancia del email marketing en la estrategia digital",
      description2:
        "El email marketing se destaca como una táctica esencial en la estrategia digital de cualquier negocio. Permite establecer una comunicación directa y personalizada con los clientes, fomentando la fidelización y el engagement. Además, es una herramienta altamente medible y permite obtener resultados tangibles en términos de conversiones y retorno de inversión.",
      subtitle3: "Construcción de una lista de suscriptores efectiva",
      description3:
        "El éxito del email marketing radica en contar con una lista de suscriptores de calidad. Exploraremos las mejores prácticas para construir una lista sólida, como la implementación de formularios de suscripción estratégicamente ubicados, la creación de lead magnets atractivos y la segmentación inteligente de la audiencia. Estas acciones asegurarán que tus campañas lleguen a las personas adecuadas en el momento oportuno.",
      subtitle4: "Diseño de campañas de email efectivas",
      description4:
        "La clave para captar la atención del receptor y lograr conversiones está en el diseño de las campañas de email. Exploraremos las mejores prácticas para crear correos electrónicos atractivos y personalizados, desde la elección de una línea de asunto persuasiva hasta la creación de un contenido relevante y visualmente atractivo. Además, abordaremos la importancia de la optimización para dispositivos móviles y la inclusión de llamadas a la acción convincentes.",
      subtitle5: "Automatización y personalización del email marketing",
      description5:
        "La automatización y personalización son dos elementos clave en el email marketing moderno. Exploraremos cómo utilizar herramientas de automatización para enviar mensajes relevantes en momentos estratégicos del customer journey. Además, abordaremos la importancia de la segmentación y personalización de los correos electrónicos para brindar una experiencia única a cada suscriptor.",
      conclusion:
        "El email marketing sigue siendo una estrategia poderosa en el mundo digital. A través de su capacidad de comunicación directa y personalizada, su medibilidad y su potencial para generar resultados tangibles, el email marketing se ha consolidado como una herramienta imprescindible para impulsar la estrategia digital de cualquier negocio. Aprovecha las mejores prácticas y herramientas disponibles.",
      details: [
        "email marketing",
        "estrategia digital",
        "resultados tangibles",
        "táctica de marketing",
        "negocio",
        "Digital",
        "lista de suscriptores",
        "formularios de suscripción",
        "lead magnets",
        "segmentación",
        "campañas",
        "diseño de campañas",
        "correos electrónicos",
        "línea de asunto",
        "contenido relevante",
        "optimización para dispositivos móviles",
        "llamadas a la acción",
        "fidelización",
        "engagement",
        "medible",
        "conversiones",
        "retorno de inversión",
        "automatización",
        "personalización",
        "customer journey",
        "segmentación",
        "experiencia única",
      ],
      autor: "Dicheell Duran",
      autorimage: img_bda1,
      autorInstagram: "https://www.instagram.com/",
      autorFacebook: "https://es-la.facebook.com/",
      autorTwitter: "https://twitter.com/?lang=es",
      autorDescription: "Programador de Página Web",
    },
  ];

  const scrollToRef = useRef([]);

  // seccion tags
  const [selectedTag, setSelectedTag] = useState("");

  const handleTagClick = (tag) => {
    if (selectedTag === tag) {
      setSelectedTag("");
      removeHighlight();
    } else {
      removeHighlight();
      setSelectedTag(tag);
      highlightTag(tag);
    }
  };

  const highlightTag = (tag) => {
    const contentElements = document.querySelectorAll(".content p");

    contentElements.forEach((element) => {
      const html = element.innerHTML;
      const highlightedHtml = html.replace(
        new RegExp(`\\b${tag}\\b`, "gi"),
        `<span class="highlighted">${tag}</span>`
      );
      element.innerHTML = highlightedHtml;
    });

    const tagElements = document.querySelectorAll(".sidebar-item.tags li a");
    tagElements.forEach((element) => {
      if (element.textContent === tag) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  };

  const removeHighlight = () => {
    const highlightedElements = document.querySelectorAll(
      ".content p .highlighted"
    );
    highlightedElements.forEach((element) => {
      element.outerHTML = element.innerHTML;
    });

    const activeTagElement = document.querySelector(
      ".sidebar-item.tags li a.active"
    );
    if (activeTagElement) {
      activeTagElement.classList.remove("active");
    }
  };
  // fin seccion tags

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);

  return (
    <div id="section_blog">
      <section id="menu">
        <Menu />
      </section>
      <section id="blog" className="blog">
        <div className="container">
          <div className="row g-5">
            {headings.map((heading, index) => (
              <div className="col-lg-12" key={heading.id}>
                <article
                  key={heading.id}
                  id={`section-${heading.id}`}
                  className="blog-details"
                >
                  <div className="post-img">
                    <img
                      src={heading.image1}
                      alt="blog"
                      className="img-fluid"
                    />
                  </div>
                  <div className="title">
                    <h2>{heading.title}</h2>
                  </div>
                  <div className="meta-top">
                    <ul>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-person"></i>{" "}
                        <a href="#!">{heading.public}</a>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="bi bi-clock"></i>{" "}
                        <a href="#!">
                          <time>Publicado el {heading.public}</time>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="content"
                    ref={(el) => (scrollToRef.current[index] = el)}
                  >
                    <h3 id="subtitle1">{heading.subtitle1}</h3>
                    <p>{heading.description1}</p>
                    <h3 id="subtitle2">{heading.subtitle2}</h3>
                    <p>{heading.description2}</p>
                    <blockquote>
                      <p>{heading.conclusion}</p>
                    </blockquote>
                    <h3 id="subtitle3">{heading.subtitle3}</h3>
                    <p>{heading.description3} </p>
                    <h3 id="subtitle4">{heading.subtitle4}</h3>
                    <p>{heading.description4}</p>
                    <img
                      src={heading.image2}
                      className="img-fluid"
                      alt="blog"
                    />

                    <h3 id="subtitle5">{heading.subtitle5}</h3>
                    <p>{heading.description5}</p>
                    {headings.map((heading, index) => (
                      <div className="meta-bottom" key={heading.id}>
                        <div className="sidebar-item tags">
                          <ul>
                            {headings.map((heading, index) => (
                              <div className="meta-bottom" key={heading.id}>
                                <div className="sidebar-item tags">
                                  <ul>
                                    {heading.details.map((tag) => (
                                      <li key={tag}>
                                        <a
                                          href="#!"
                                          className={
                                            selectedTag === tag ? "active" : ""
                                          }
                                          onClick={() => handleTagClick(tag)}
                                        >
                                          {tag}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="table-button">
                    <div className="button d-flex justify-content-end">
                      <Link to="/Blog">
                        <p>VOLVER</p>
                      </Link>
                    </div>
                  </div>
                </article>
                {/*<div className="post-author d-flex align-items-center">
                  <img
                    src={heading.autorimage}
                    className="rounded-circle flex-shrink-0"
                    alt=""
                  />
                  <div>
                    <h4>{heading.autor}</h4>
                    <div className="social-links">
                      <a href={heading.autorTwitter}>
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href={heading.autorFacebook}>
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href={heading.autorInstagram}>
                        <i className="biu bi-instagram"></i>
                      </a>
                    </div>
                    <p>{heading.autorDescription}</p>
                  </div>
                </div> */}
              </div>
            ))}
            {/*
            <div className="col-lg-3 ">
              <div className="table-top">
                <div className="table">
                  <h3>Tabla de Contenido</h3>
                  {headings.map((heading, index) => (
                    <ul className="list-group mt-3">
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 1}.{heading.subtitle1}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 2}.{heading.subtitle2}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 3}.{heading.subtitle3}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 4}.{heading.subtitle4}
                      </li>
                      <li
                        key={heading.id}
                        className="list-group-item"
                        onClick={() => handleSubtitleClick(index)}
                      >
                        {index + 5}. {heading.subtitle5}
                      </li>
                    </ul>
                  ))}
                  
                </div>
              </div>
            </div>
             */}
          </div>
        </div>
      </section>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_blog">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
}

export default BlogDetails1;
