import React, { useEffect, useState } from "react";
import "./Info.css";
import $ from "jquery";

function Info() {
  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(true);

  function HandleChangeTheme() {
    theme === "light" ? setTheme("dark") : setTheme("light");
  }

  useEffect(() => {
    if (!loading) {
      $("body").attr("theme", theme);
      localStorage.setItem("theme", theme);
    }
  }, [theme, loading]);

  useEffect(() => {
    let varTheme = localStorage.getItem("theme");
    $("body").attr("theme", varTheme === undefined ? "light" : varTheme);

    varTheme === undefined ? setTheme("light") : setTheme(varTheme);

    setLoading(false);
  }, []);

  return (
    <div className="info1 d-flex align-items-center">
      <div className="container-fluid d-flex align-items-center justify-content-md-between">
        <div className="contact-info d-flex  align-items-center">
          <a href="mailto:ventas@expomarketing.biz">
            <i className="bi bi-envelope d-flex  align-items-center">
              <p className="hide">ventas@expomarketing.biz</p>
            </i>
          </a>
          <a href="tel:+582127930266">
            <i className="bi bi-phone d-flex align-items-center ms-4">
              <p className="hide">+58 212-7930266</p>
            </i>
          </a>
        </div>
        <div className="social-links d-none d-md-flex align-items-center">
          <a href="#!" onClick={HandleChangeTheme}>
            <i className="bi bi-moon" title="Modo"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Info;
