import React, { useState } from "react";
import "./Plans.css";

const Plans = ({ name, description, price, onSelect }) => {
  const [selectedPrice, setSelectedPrice] = useState(price);

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const roundedValue = Math.round(value / 5) * 5;
    setSelectedPrice(roundedValue);
    onSelect(name, roundedValue * 0.008);
  };

  const handleCotizarClick = () => {
    const emailSubject = "Cotización de Plan";
    const emailBody = `Nombre del plan: ${name}\nPrecio seleccionado: ${selectedPrice} Contacto`;
    const mailtoUrl = `mailto:radarsystems.oficial@gmail.com?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;

    window.open(mailtoUrl);
  };

  return (
    <div className="box">
      <h3>{name}</h3>
      <div className="caja">
        <p>{description}</p>
      </div>
      <div className="price">
        <h4>
          <sup>$</sup>
          {price}
          <span> /mes</span>
        </h4>
      </div>
      <div className="bar-price">
        <input
          className="input-plan"
          name="price"
          type="range"
          min="100"
          max="10000"
          step="1975"
          value={selectedPrice}
          onChange={handleInputChange}
        />
        <p>{selectedPrice} Contacto</p>
      </div>
      <div>
        <div className="row">
          <button
            type="button"
            className="button-plan"
            onClick={handleCotizarClick}
          >
            Cotizar
          </button>
        </div>



      </div>
    </div>
  );
};

export default Plans;
