import React from 'react';
import { Link } from 'react-router-dom';
import "./Error.css";


function Error() {
  return (
    <div id="error404" className="d-flex align-items-center">
      <div className="container" >
        <h1>ER<span>ROR</span></h1>
        <h2>La página que estás buscando no existe.</h2>
        <div className="d-center">
          <Link to={"/"}>
            <h2 className="btn-get-started">VOLVER AL INICIO</h2>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Error;
