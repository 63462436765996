import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import About from './pages/about/About';
import AboutInfo from './pages/about/aboutinfo/AboutInfo';
import AboutApi from './pages/about/aboutapi/AboutApi';
import AboutApiPush from './pages/about/aboutapi/AboutApiPush';
import AboutApiEmail from './pages/about/aboutapi/AboutApiEmail';
import AboutApiSms from './pages/about/aboutapi/AboutApiSms';
import AboutMarketing from './pages/about/aboutmarketing/AboutMarketing';
import Question from './pages/about/questions/Question';
import QuestionList from './pages/about/questions/questionList/QuestionList';
import Servicios from './pages/servicios/Services';
import PlanesTarifas from './pages/planes/RatesPlans';
import Plans from './pages/planes/Plans';
import PlanModal from './pages/planes/planModal/PlanModal';
import PDFDocument from './pages/planes/planModal/pdfDocument/PDFDocument';
import Fidelidad from './pages/planes/fidelidad/Fidelidad';
import Clients from './pages/planes/clientes/Clients';
import Recursos from './pages/recursos/Resources';
import Blog from './pages/blog/Blog';
import BlogDetails1 from './pages/blog/blogDetails/BlogDetails1';
import BlogDetails2 from './pages/blog/blogDetails/BlogDetails2';
import BlogDetails3 from './pages/blog/blogDetails/BlogDetails3';
import Contacto from './pages/contacto/Contact';
import Form from './pages/contacto/form/Form';
import Error from './pages/error/Error';
import Marketing from './pages/servicios/marketing/Marketing';
import Email from './pages/servicios/email/Email';
import Chat from './pages/servicios/chat/Chat';
import Ventas from './pages/servicios/ventas/Ventas';
import { DeviceProvider } from './context/TypeDevice';


const router = createBrowserRouter ([
  {
    path: "/",
    element:  <About />,
    errorElement: <Error />,
  },
    {
      path: "/AboutInfo",
      element:  <AboutInfo />,
      errorElement: <Error />,
    },
      {
        path: "/AboutApi",
        element:  <AboutApi />,
        errorElement: <Error />,
      },
        {
          path: "/AboutApiPush",
          element:  <AboutApiPush />,
          errorElement: <Error />,
        },
        {
          path: "/AboutApiEmail",
          element:  <AboutApiEmail />,
          errorElement: <Error />,
        },
        {
          path: "/AboutApiSms",
          element:  <AboutApiSms />,
          errorElement: <Error />,
        },
      {
        path: "/AboutMarketing",
        element:  <AboutMarketing />,
        errorElement: <Error />,
      },
      {
        path: "/Question",
        element:  <Question />,
        errorElement: <Error />,
      },
        {
          path: "/QuestionList",
          element:  <QuestionList />,
          errorElement: <Error />,
        },
  {
    path: "/Servicios",
    element:  <Servicios />,
    errorElement: <Error />,
  }, 
    {
      path: "/Plans",
      element:  <Plans />,
      errorElement: <Error />,
    },
    {
      path: "/PlanesTarifas",
      element: <PlanesTarifas />,
      errorElement: <Error />,
    },
    {
      path: "/PlanModal",
      element:  <PlanModal />,
      errorElement: <Error />,
    },
    {
      path: "/PDFDocument",
      element:  <PDFDocument />,
      errorElement: <Error />,
    },
      {
        path: "/Clients",
        element:  <Clients />,
        errorElement: <Error />,
      },
      {
        path: "/Fidelidad",
        element:  <Fidelidad />,
        errorElement: <Error />,
      },
  {
    path: "/Recursos",
    element:  <Recursos />,
    errorElement: <Error />,
  },
  {
    path: "/Blog",
    element:  <Blog />,
    errorElement: <Error />,
  },
    {
      path: "/BlogDetails1",
      element:  <BlogDetails1 />,
      errorElement: <Error />,
    },
    {
      path: "/BlogDetails2",
      element:  <BlogDetails2 />,
      errorElement: <Error />,
    },
    {
      path: "/BlogDetails3",
      element:  <BlogDetails3 />,
      errorElement: <Error />,
    },
  {
    path: "/Marketing",
    element:  <Marketing />,
    errorElement: <Error />,
  },
  {
    path: "/Chat",
    element:  <Chat />,
    errorElement: <Error />,
  },
  {
    path: "/Ventas",
    element:  <Ventas />,
    errorElement: <Error />,
  },
  {
    path: "/Email",
    element:  <Email />,
    errorElement: <Error />,
  },
  {
    path: "/Contacto",
    element:  <Contacto />,
    errorElement: <Error />,
  },  
    {
      path: "/Form",
      element:  <Form />,
      errorElement: <Error />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DeviceProvider>
      <RouterProvider router={router} />
    </DeviceProvider>
  </React.StrictMode>
);

