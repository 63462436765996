import React, { useState, useEffect } from "react";
import "./Plans.css";
import Plans from "./Plans";
import Menu from "../../components/menu/Menu";
import Footer from "../../components/footer/Footer";
import PlanModal from "./planModal/PlanModal";
import Fidelidad from "./fidelidad/Fidelidad";
import Clients from "./clientes/Clients";

const RatesPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePlanSelect = (name, value, description) => {
    setSelectedPlan({ name, value, description });
  };

  const planDetails = [
    {
      name: "Gran Enviador",
      description: "Organizaciones con necesidades especificas.",
      price: 100,
      details: [
        {
          title: "Plantillas Dinámicas + Pruebas",
          info: [
            {
              info1:
                "Diseñe Plantillas impactantes con representación HTML de apuntar al hacer clic, pruebas condicionales.",
              info2:
                "Base de datos ilimitada de contactos.",
              info3:
                "Sistema de envios de email masivos con inteligencia artificial.",
              info4:
                "Costos por envios de email masivo o via api-rest.",
              info5:
                "Plataforma de envios de sms masivos o via api, incluye diez (10) mensajes de texto gratis, el primer mes.",
              info6:
                "Direcciones ips dedicadas.",
              info7:
                "Servidores smtps dedicados.",
              info8:
                "Footer personalizable",
              info9:
                "Footer sin la marca radarsystems.",
              info10:
                "Administrador de multi-cuentas. ",
              info11:
                "Super-administrador.",
              info12:
                "Roles y perfiles.",
              info13:
                "Cuatro (04) usuarios por cuenta.",
              info14:
                "Optimizador de horario de envio.",
              info15:
                "Sistema de log, auditoria y seguridad para monitorear a los usuarios.",
              info16:
                "Servidor de black list (Lista negra de correos electronicos).",
              info17:
                "Depurador de base de datos en tiempo real.",
              info18:
                "Triple sistema de remocion de listas (opt out, opt net, reporte spam).",
              info19:
                "Plataforma de envios de sms masivos o via api, a traves de nuestros numeros cortos, incluye treinta (30) mensajes de texto gratis (envios adicionales cotizar) duracion de los mensajes 60 dias continuos.",
              info20:
                "Editor de boletines drag and drop (arrastrar y soltar) con inteligencia artificial ai.",
              info21:
                "Acceso a banco de imágenes ilimitado.",
              info22:
                "Segmentador avanzado.",
              info23:
                "Creacion de listas ilimitadas.",
              info24:
                "Creacion de segmentos ilimitados.",
              info25:
                "Acortador de url ilimitado con estadisticas sin vencimiento.",
              info26:
                "Botonera de url para redes sociales y medios digitales personalizable, con enlaces y estadisticas ilimitadas.",
              info27:
                "Botonera de qr para generar (tarjeta de presentacion, catalogo de productos o servicios, whatsapp, y redes sociales) ilimitado.",
              info28:
                "Landing page de registro (formularios) en linea ilimitado.",
              info29:
                "Tres (03) landing page corporativos perzonalizables con inteligencia artificial ai.",
              info30:
                "Un (01) landin page de ecommerce con carrito de compra y boton de pago (tarifas de los botones de pago no incluido).",
              info31:
                "Sistema de inventario basico.",
              info32:
                "Certificado ssl incluido.",
              info33:
                "Hospedaje incluido.",
              info34:
                "Dominios personalizados del cliente, (costos opcionales).",
              info35:
                "Sistema de encuesta personalizable, incluye estadisticas en tiempo real.",
              info36:
                "Auto-responder para todos los registros en linea.",
              info37:
                "Chat-boot con inteligencia artificial ai incluido.",
              info38:
                "Estadisticas en tiempo real de cada herramienta.",
              info39:
                "Soporte tecnico ininterrumpido (24-7) por correo electronico y chat-boot.",
              info40:
                "Onboarding personalizado, cinco (05) sesiones incluidos."
            },
          ],
        },
        {
          title: "Análisis Perspicaz",
          info: [
            {
              info1:
                "Acceda a una fuente de actividad rica en datos, estadísticas agregadas y controle su reputación de remitente.",
              info25: "Detalle 33",
              info2:
                "Auto-responder para todos los registros en linea.",
              info37:
                "Chat-boot con inteligencia artificial ai incluido.",
              info38:
                "Estadisticas en tiempo real de cada herramienta.",
              info39:
                "Soporte tecnico ininterrumpido (24-7) por correo electronico y chat-boot.",
              info40:
                "Onboarding personalizado, cinco (05) sesiones incluidos."
            },
          ],
        },
        {
          title: "Tiempos de Respuesta garantizados",
          info: [
            {
              info1:
                "Obtenga ayuda más rápido de nuestro equipo de soporte experto a través de ticket web, chat en vivo o llamadas telefónicas.",
              info2: "Detalle 34",
            },
          ],
        },
        {
          title: "Gestión de Sub-usuarios",
          info: [
            {
              info1:
                "Segmente su envío, asigne permisos y limites de crédito, vea estadísticas separadas mediante programación o en la interfaz.",
              info2: "Detalle 5"
            },
          ],
        },
        {
          title: "Validaciones de Correo Electrónico",
          info: [
            {
              info1:
                "Proteja su reputación de envío validando las direcciones de correo electrónico antes de enviarlas mediante programación.",
              info2: "Detalle 6",
            },
          ],
        },
      ],
    },
    {
      name: "Enterprise",
      description: "Perfecto para expertos en marketing.",
      price: 99,
      details: [
        {
          title: "Herramientas de Optimización de Entrega",
          info: [
            {
              info1:
                "Maximice la ubicación de su bandeja de entrada con registros SPF y DKIM personalizado, monitoreo y administración.",
              info2: "Detalle 3",
            },
          ],
        },
        {
          title: "Información sobre la capacidad de entrega",
          info: [
            {
              info1:
                "Obtenga información procesable para optimizar su programa de correo electrónico con panel de información de entregabilidad.",
              info2: "Detalle 4",
            },
          ],
        },
      ],
    },
    {
      name: "Pymes",
      description: "Ideal para negocio en crecimiento y rentable.",
      price: 59,
      details: [
        {
          title: "Gestión de Sub-usuarios",
          info: [
            {
              info1:
                "Segmente su envío, asigne permisos y limites de crédito, vea estadísticas separadas mediante programación o en la interfaz.",
              info2: "Detalle 5",
            },
          ],
        },
        {
          title: "Validaciones de Correo Electrónico",
          info: [
            {
              info1:
                "Proteja su reputación de envío validando las direccones de correo electrónico antes de enviarlas mediante programación.",
              info2: "Detalle 6",
            },
          ],
        },
      ],
    },
    {
      name: "Básico",
      description: "Descubre lo mejor que ofrece RadarSystems.",
      price: 39,
      details: [
        {
          title: "Gestión de Sub-usuarios",
          info: [
            {
              info1:
                "Segmente su envío, asigne permisos y limites de crédito, vea estadísticas separadas mediante programación o en la interfaz.",
              info2: "Detalle 5",
            },
          ],
        },
        {
          title: "Validaciones de Correo Electrónico",
          info: [
            {
              info1:
                "Proteja su reputación de envío validando las direccones de correo electrónico antes de enviarlas mediante programación.",
              info2: "Detalle 6",
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);

  return (
    <div id="section_planes">
      <section id="menu">
        <Menu />
      </section>
      <section id="titulo" className="titulo_planes">
        <div className="container">
          <div className="servicios-title">
            <h3>
              NUESTROS<span> PLANES</span>
            </h3>
            <p>Convierte visitantes en clientes fieles a tu marca.</p>
          </div>
        </div>
      </section>
      <div id="tarifas" className="tarifas">
        <div className="container">
          <div className="row">
            {planDetails.map((plan, index) => (
              <div className="col-lg-3" key={index}>
                <Plans {...plan} onSelect={handlePlanSelect} />
              </div>
            ))}
            <div className="col-lg-12 mt-4">
              {selectedPlan && (
                <div className="card">
                  <div className="card-body section-bg">
                    <div className="section-title-pricing">
                      <h3>{selectedPlan.name}</h3>
                      <h4 className="mb-4">
                        Precio:
                        <span> {selectedPlan.value} / mes</span>
                      </h4>
                    </div>
                    {planDetails
                      .find((plan) => plan.name === selectedPlan.name)
                      .details.map((detail, index) => (
                        <table className="table" key={index}>
                          <thead className="table-title">
                            <tr>
                              <th colSpan="2">{detail.title}</th>
                            </tr>
                          </thead>
                          <tbody className="table-info">
                            <tr>
                              <td>{detail.info[0].info1}</td>
                            </tr>
                            <tr>
                              <td>{detail.info[0].info2}</td>
                            </tr>
                            <tr>
                              <td>{detail.info[0].info3}</td>
                            </tr>
                            <tr>
                              <td>{detail.info[0].info4}</td>
                            </tr>
                          </tbody>
                        </table>
                      ))}

                    <div className="open-modal">
                      <button
                        type="button"
                        className="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#PlanModal"
                        onClick={() =>
                          handlePlanSelect(
                            selectedPlan.name,
                            selectedPlan.value,
                            selectedPlan.description
                          )
                        }
                      >
                        <p>
                          {" "}
                          Más información del Plan{" "}
                          <i className="bi bi-arrow-right"></i>
                        </p>
                      </button>
                      {selectedPlan && (
                        <PlanModal
                          plan={planDetails.find(
                            (p) => p.name === selectedPlan.name
                          )}
                          selectedPlan={selectedPlan}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <section className="servicios">
        <Clients />
        <Fidelidad />
      </section>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_planes">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
};

export default RatesPlans;
