import React from "react";
import "./Clients.css";
import Zoom from "../../../assets/img_clients/grupo_zoom.png";
import Milpagos from "../../../assets/img_clients/milpagos.png";
import Corpoelec from "../../../assets/img_clients/corpoelec.png";
import SuperPlazas from "../../../assets/img_clients/plazas.png";

const Clients = () => {
  return (
    <section id="clients" className="clients section-bg">
      <div className="container" data-aos="zoom-in">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={Zoom} className="img-fluid" alt="Zoom" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={Milpagos} className="img-fluid" alt="Milpagos" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={SuperPlazas} className="img-fluid" alt="SuperPlazas" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={Milpagos} className="img-fluid" alt="" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={Zoom} className="img-fluid" alt="" />
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={Corpoelec} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;


