import React, { useState, useEffect } from "react";
import "./QuestionList.css";
import Menu from "../../../../components/menu/Menu";
import Footer from "../../../../components/footer/Footer";

function QuestionList() {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [isAllActive, setIsAllActive] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const toggleAccordion = (index) => {
    setActiveAccordion(index);
    setIsAllActive(index === null);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const accordions = [
    {
      title: "Email Marketing",
      questions: [
        {
          id: "1",
          question:
            "¿Cómo puedo aumentar la tasa de apertura de mis correos electrónicos?",
          answer:
            "Segmentar y validar tu lista de contactos con nuestro servicio de validación de cuentas de correo electrónico, personalizar tus asuntos, crear contenido optimizado y diseñar plantillas HTML llamativas con nuestro editor de plantillas estilo Canva.",
        },
        {
          id: "2",
          question:
            "¿Cuál es la mejor frecuencia de envío de correos electrónicos para mi audiencia?",
          answer:
            "La frecuencia óptima varía, prueba y ajusta según la respuesta de tu audiencia. Con nuestro avanzado panel de estadísticas puedes llevar el análisis de tus campañas al siguiente nivel con un detallado sistema estadístico en tiempo real.",
        },
        {
          id: "3",
          question:
            "¿Cómo puedo mejorar la entrega de mis correos electrónicos en la bandeja de entrada en lugar de la carpeta de spam?",
          answer:
            "Haciendo las debidas configuraciones previas antes de iniciar el envío de campañas como SPF, DKIM, DMARK, BIMI, registro DNS, entre otros. Utiliza prácticas de autenticación y evita palabras de spam en tus correos. ",
        },
        {
          id: "4",
          question:
            "¿Cuáles son las mejores prácticas para diseñar un correo electrónico atractivo y efectivo?",
          answer:
            "Diseña con claridad, brevedad y llamadas a la acción destacadas. En RadarSystems tenemos herramientas que potencian la creación de campañas de email marketing.",
        },
        {
          id: "5",
          question:
            "¿Cómo puedo medir y analizar la efectividad de mis campañas de email marketing?",
          answer:
            "Mide tasas de apertura, clics y conversiones para evaluar el rendimiento. Con nuestro panel de estadísticas avanzado puedes tener un análisis detallado en tiempo real.",
        },
      ],
    },
    {
      title: "Email Transaccional",
      questions: [
        {
          id: "6",
          question:
            "¿Cuál es la diferencia entre un correo electrónico transaccional y un correo electrónico de marketing?",
          answer:
            "El transaccional es para comunicaciones individuales, mientras que el marketing es para campañas masivas.",
        },
        {
          id: "7",
          question:
            "¿Qué información y elementos clave deben incluirse en un correo electrónico transaccional?",
          answer:
            "Incluye información relevante (facturas, confirmaciones, compras, notificaciones) y personalización cuando sea posible.",
        },
        {
          id: "8",
          question:
            "¿Cómo puedo personalizar y automatizar los correos electrónicos transaccionales?",
          answer:
            "Utiliza plantillas y automatización para agilizar el proceso de envío. ",
        },
        {
          id: "9",
          question:
            "¿Qué medidas de seguridad debo tomar para garantizar la entrega y la integridad de los correos electrónicos transaccionales?",
          answer:
            "Implementa autenticación y cifrado para proteger la seguridad de los correos.",
        },
        {
          id: "10",
          question:
            "¿Cuál es la importancia de la entrega en tiempo real de los correos electrónicos transaccionales?",
          answer:
            "La entrega en tiempo real es crucial para la confirmación y la experiencia del usuario.",
        },
      ],
    },
    {
      title: "Sms Marketing",
      questions: [
        {
          id: "11",
          question:
            "¿Cuál es la efectividad del SMS marketing en comparación con otros canales de marketing?",
          answer:
            "El SMS tiene una alta tasa de apertura y es efectivo para comunicaciones breves.",
        },
        {
          id: "12",
          question:
            "¿Cómo puedo obtener y administrar una lista de suscriptores para el SMS marketing?",
          answer:
            "Utiliza una plataforma de gestión de suscriptores como RadarSystems en la çual puedes crear listas y segmentos de tus contactos. ",
        },
        {
          id: "13",
          question:
            "¿Cómo puedo personalizar y segmentar los mensajes SMS para aumentar su relevancia y efectividad?",
          answer:
            "	Segmenta y personaliza tus mensajes para aumentar la relevancia.",
        },
        {
          id: "14",
          question:
            "¿Cuáles son las mejores prácticas para incluir llamadas a la acción (CTA) efectivas en los mensajes SMS de marketing?",
          answer:
            "	Utiliza CTAs claras y concisas para impulsar la acción, utiliza nuestro acortador de url Medusa puedes generar una mayor interacción en tus campañas.",
        },
        {
          id: "15",
          question: "¿Puedo alojar mis listas de contactos en RadarSystems?",
          answer:
            "Si, puedes alojar tus listas y segmentos y de igual forma puedes generarlos dentro de nuestra plataforma.",
        },
      ],
    },
    {
      title: "Sms Transaccional",
      questions: [
        {
          id: "16",
          question: "¿Qué tipos de mensajes se consideran SMS transaccionales?",
          answer:
            "Incluye mensajes de confirmación, notificaciones de entrega y actualizaciones de cuenta",
        },
        {
          id: "17",
          question:
            "¿Cómo puedo integrar y automatizar los mensajes SMS transaccionales en mis sistemas y aplicaciones?",
          answer:
            "Integra nuestra API para enviar mensajes automáticamente desde tus sistemas y obtén tus estadísticas personalizadas y en tiempo real.",
        },
        {
          id: "18",
          question:
            "¿Cuál es la importancia de la entrega y la velocidad de los mensajes SMS transaccionales?",
          answer:
            "La entrega rápida es crucial para la experiencia del usuario y la confiabilidad.",
        },
        {
          id: "19",
          question:
            "¿Qué medidas de seguridad se deben implementar para proteger la privacidad y la integridad de los mensajes SMS transaccionales?",
          answer:
            "Utiliza protocolos de seguridad como encriptación y autenticación.",
        },
        {
          id: "20",
          question:
            "¿Cómo puedo medir y realizar un seguimiento de la efectividad de los mensajes SMS transaccionales?",
          answer:
            "Mide la tasa de entrega y el tiempo de respuesta para evaluar la efectividad.",
        },
      ],
    },
    {
      title: "Notificaciones Push Masivas",
      questions: [
        {
          id: "21",
          question:
            "¿Cómo puedo segmentar a mis usuarios y personalizar los mensajes en notificaciones push masivas?",
          answer:
            "Segmenta a tus usuarios y personaliza los mensajes según sus preferencias.",
        },
        {
          id: "22",
          question:
            "¿Qué plataforma puedo utilizar para enviar notificaciones push a gran escala?",
          answer:
            "Utiliza una plataforma confiable para enviar notificaciones a gran escala como RadarSystems.",
        },
        {
          id: "23",
          question:
            "¿Cuáles son las regulaciones de privacidad que debo cumplir al enviar notificaciones push masivas?",
          answer:
            "Asegúrate de cumplir con las regulaciones de privacidad y solicitar el consentimiento del usuario.",
        },
        {
          id: "24",
          question:
            "¿Cómo puedo crear mensajes efectivos en notificaciones push masivas?",
          answer:
            "Crea mensajes breves y atractivos que impulsen la interacción.",
        },
        {
          id: "25",
          question:
            "¿Qué métricas debo medir para evaluar el rendimiento de mis notificaciones push masivas?",
          answer:
            "Realiza un seguimiento de las métricas de entrega y la tasa de clics para evaluar el rendimiento.",
        },
      ],
    },
    {
      title: "Notificaciones Push Transaccionales",
      questions: [
        {
          id: "26",
          question:
            "¿Qué tipo de notificaciones se consideran notificaciones push transaccionales?",
          answer:
            "Las notificaciones inmediatas para confirmar acciones o informar cambios relevantes.",
        },
        {
          id: "27",
          question:
            "¿Cómo puedo integrar notificaciones push transaccionales en mi sistema?",
          answer:
            "Utiliza nuestra API para integrar las notificaciones en tu sistema.",
        },
        {
          id: "28",
          question:
            "¿Qué características deben tener las notificaciones push transaccionales?",
          answer:
            "Asegúrate de que las notificaciones sean claras y relevantes para el usuario.",
        },
        {
          id: "29",
          question:
            "¿Qué medidas de seguridad debo implementar para proteger la integridad de las notificaciones push transaccionales?",
          answer:
            "Implementa medidas de seguridad para proteger la integridad de las notificaciones.",
        },
        {
          id: "30",
          question:
            "¿Qué métricas debo medir para evaluar la eficacia de mis notificaciones push transaccionales?",
          answer:
            "Mide el tiempo de entrega y la tasa de interacción para evaluar la eficacia.",
        },
      ],
    },
  ];

  const filteredAccordions = accordions.filter((accordion) => {
    const lowercaseQuery = searchQuery.toLowerCase();
    return (
      accordion.title.toLowerCase().includes(lowercaseQuery) ||
      accordion.questions.some((question) =>
        question.question.toLowerCase().includes(lowercaseQuery)
      )
    );
  });

  const allQuestions = filteredAccordions.flatMap(
    (accordion) => accordion.questions
  );
  const totalPages = Math.ceil(allQuestions.length / itemsPerPage);
  const showPaginator = isAllActive && totalPages > 1;

  useEffect(() => {
    setIsAllActive(activeAccordion === null);
  }, [activeAccordion]);

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);
  
  return (
    <div id="#section_questions">
      <section id="menu-banner">
        <Menu />
      </section>
      <section id="titulo">
        <div className="container">
          <div className="section-title">
            <h3>
              Preguntas <span>Frecuentes</span>
            </h3>
            <p>Preguntas más frecuentes planteadas por nuestros clientes.</p>
          </div>
        </div>
      </section>
      <div id="question_list" className="question_list">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="row justify-content-end">
                <div className="col-lg-4">
                  <div className="input-group search">
                    <input
                      type="search"
                      className="form-control search-input"
                      placeholder="Buscar"
                      aria-label="Buscar"
                      aria-describedby="search-addon"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <span
                      className="input-group-text border-0"
                      id="search-addon"
                    >
                      <i className="bi bi-search"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="question-title-accordion justify-content-center">
                <div className="box-accordion">
                  <button
                    className={`button_questions ${
                      isAllActive ? "active" : ""
                    } ${isAllActive ? "active-button" : ""}`}
                    onClick={() => toggleAccordion(null)}
                  >
                    Todos
                  </button>
                </div>
                {filteredAccordions.map((accordion, index) => (
                  <div key={index} className="box-accordion">
                    <button
                      className={`button_questions ${
                        activeAccordion === index ? "active" : ""
                      }`}
                      onClick={() => {
                        toggleAccordion(index);
                        setCurrentPage(1);
                      }}
                    >
                      {accordion.title}
                    </button>
                  </div>
                ))}
              </div>
              {isAllActive &&
                allQuestions
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((question, index) => (
                    <div
                      className="accordion"
                      id={`accordion${index}`}
                      key={index}
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded="false"
                            aria-controls={`collapse${index}`}
                          >
                            {question.id}. {question.question}
                          </button>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`accordion${index}`}
                        >
                          <div className="accordion-body">
                            {question.answer}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              {!isAllActive &&
                filteredAccordions[activeAccordion].questions.map(
                  (question, index) => (
                    <div
                      className="accordion"
                      id={`accordion${index}`}
                      key={index}
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded="false"
                            aria-controls={`collapse${index}`}
                          >
                            {question.id}. {question.question}
                          </button>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`accordion${index}`}
                        >
                          <div className="accordion-body">
                            {question.answer}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              {showPaginator && (
                <div className="paginator">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center py-5">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          &laquo;
                        </button>
                      </li>

                      {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                        (pageNumber) => (
                          <button
                            key={pageNumber}
                            className={`page-link  ${
                              pageNumber === currentPage ? "active" : ""
                            }`}
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        )
                      )}

                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          &raquo;
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_questions">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
}

export default QuestionList;
