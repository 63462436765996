import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Services.css";
import Menu from "../../components/menu/Menu";
import Footer from "../../components/footer/Footer";
import services1 from "../../assets/img-services/services-1.jpg";
import services2 from "../../assets/img-services/services-2.jpg";
import services3 from "../../assets/img-services/services-3.jpg";
import services4 from "../../assets/img-services/services-4.jpg";



const services = [
  {
    image: services4,
    title: "Botonera de qr para generar (tarjeta de presentacion, catalogo de productos, whatsapp, redes sociales)",
    description: "Radarsystems lanza la primera botonera de QR al mercado, que le permite olvidarse de las tipicas tarjetas de presentación,  le permite alcanzar seguidores de forma rápida y efectiva permitiendo el escaneo de tu QR y descargar tus productos, servicios o redes sociales tales como; Instagram, Facebook, Tik Tok, WhatsApp entre otros.",
    link: "/Marketing#section_marketing",
  },
  {
    image: services1,
    title: "Botonera de conexión, conversion y comunicación de urls ",
    description: "ofrecemos la botonera más amplia, versátil y poderosa del mercado, no solo ofrece botones de enlace a redes sociales y medios digitales",
    link: "/Ventas",
  },
  {
    image: services2,
    title: "Auto-responder para todos los registros en linea",
    description: "RadarSystems le permite generar auto-responder via email y SMS en cada landing page, registro en línea y acción que realice el destinatario con los correos y SMS.",
    link: "/Marketing#section_marketing",
  },
  {
    image: services4,
    title: "Plataforma de Email Marketing",
    description: "Crea campañas a tu medida.",
    link: "/Marketing#section_marketing",
  },
  {
    image: services1,
    title: "Creación y Gestión de Empresas",
    description: "Encuentra las mejores oportunidades.",
    link: "/Ventas",
  },
  {
    image: services2,
    title: "•	Auto-responder para todos los registros en linea",
    description: "RadarSystems le permite generar auto-responder via email y SMS en cada landing page, registro en línea y acción que realice el destinatario con los correos y SMS.",
    link: "/Marketing#section_marketing",
  },
  {
    image: services3,
    title: "Administrador multi-usuarios",
    description: "Ideal para agencias de marketing y community manager",
    link: "/Ventas",
  },
  {
    image: services2,
    title: "Segmentador avanzado",
    description: "permite realizar segmentaciones o micro-segmentaciones utilizando los resultados estadísticos que se genera",
    link: "/Marketing#section_marketing",
  },
  {
    image: services1,
    title: "Acortador de URL",
    description: "Sistema de acortador de URL Rar.bio integrado en todos nuestros módulos, acortando URLs de manera automática.",
    link: "/Ventas",
  },
  {
    image: services3,
    title: "Chatbot con inteligencia artificial ",
    description: "Radaysystems pone a sus disposicion un chatbot integrado a inteligencia artificial para interactuar de manera mas efectiva",
    link: "/Ventas",
  },
  {
    image: services2,
    title: "Estadisticas en tiempo real para cada herramienta",
    description: "Todas nuestras aplicaciones poseen un tracking para monitorear estadisticas en tiempo real.",
    link: "/Marketing#section_marketing",
  },
  {
    image: services1,
    title: "Soporte tecnico ininterrumpido (24-7) por correo electronico y chatbot.",
    description: "RadarSystems cuenta con un equipo de profesionales capacitados para dar respuestas oportunas a sus requerimientos via email 24X7 una vez que haya sido abortado las respuestas de nuestro chat boot.",
    link: "/Ventas",
  },
];

const Servicios = () => {

  useEffect(() => {
    window.scrollTo("0", "0");
  }, []);

  return (
    <div id="section_servicios">
      <section id="menu">
        <Menu />
      </section>
      <section id="titulo" className="titulo_servicios">
        <div className="container" data-aos="fade-up">
          <div className="servicios-title">
            <h3>
              NUESTROS<span> SERVICIOS</span>
            </h3>
            <p>Convierte visitantes en clientes fieles a tu marca.</p>
          </div>
        </div>
      </section>
      <div id="servicios" className="servicios_list">
        <div className="container" data-aos="fade-up">
          <div className="row">
            {services.map((service, index) => (
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="100"
                key={index}
              >
                <div className="member">
                  <div className="member-img">
                    <img src={service.image} className="img-fluid" alt="" />
                  </div>
                  <div className="member-info">
                    <h4>{service.title}</h4>
                    <span>{service.description}</span>
                  </div>
                  <div className="social">
                    <Link to={service.link} className="links">
                      Leer más<i className="bi bi-caret-right-fill"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <section id="servicios-letter" className="servicios-letter">
        <div className="servicios-letters">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1>¿Todo listo para empezar?</h1>
                <h6>CREA UNA CUENTA GRATUITA, SIN TARJETA BANCARIA.</h6>
                <div className="d-flex justify-content-center">
                  <Link to={"/Contacto"} className="btn-get-started">
                    Contáctanos
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div className="back-to-top d-flex align-items-center justify-content-center active">
        <a href="#section_servicios">
          <i className="bi bi-arrow-up-short"></i>
        </a>
      </div>
    </div>
  );
};



export default Servicios;
