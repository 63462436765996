import React from 'react'

function AboutMarketing() {
  return (
    <div id="section2">
    <div id="negocio" className="negocio">
    <div className="portfolio-container" data-aos="fade-up">
      <div className="col-lg-12  portfolio-item filter-app">
        <div className="container" data-aos="fade-up">
          <div className="negocio-title">
            <h3>
            Plataforma de <span>Marketing</span>
            </h3>
          </div>
          <div className="row">
            
            <div
              className="col-lg-4 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bi bi-phone"></i>
                </div>
                <h4>
                  <a href="#!">SMS Masivo</a>
                </h4>
                <p>Utilizando Nuestra Plataforma De Comunicación.</p>
              </div>
            </div>
            <div
              className="col-lg-4 d-flex align-items-stretch mt-4 mt-lg-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bi bi-envelope"></i>
                </div>
                <h4>
                  <a href="#!">Email Masivo</a>
                </h4>
                <p>Sin Tener Conocimientos De Computación.</p>
              </div>
            </div>
            <div
              className="col-lg-4  d-flex align-items-stretch mt-4 mt-lg-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bi bi-bell"></i>
                </div>
                <h4>
                  <a href="#!">Push Masivo</a>
                </h4>
                <p>Sin Tener Conocimientos De Computación.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
    </div>
  )
}

export default AboutMarketing
