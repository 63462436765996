import React, { useState } from "react";
import axios from "axios";

import "./Form.css";

function Form() {
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    email: "",
    description: ""
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    axios
      .post("/api/send-email", formData)
      .then((response) => {
        console.log(response.data);
        setsuccessMessage("Correo electrónico enviado con éxito");
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage("No se pudo enviar el correo electrónico");
      });
  };

  
  

  return (
    <div className="formulario">
      <h1>Ponerse en contacto</h1>
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="nombre">Nombre</label>
          <input
            type="text"
            className="form-control"
            id="nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="apellido">Apellido</label>
          <input
            type="text"
            className="form-control"
            name="apellido"
            id="apellido"
            value={formData.apellido}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description">Descripción</label>
          <textarea
            className="form-control"
            rows="2"
            name="description"
            id="description"
            value={formData.description}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="row">
          <div className="d-flex justify-content-end">
            <button type="submit" className="button-form">
              Enviar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Form;