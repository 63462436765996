import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../../../assets/img_logos_radar/banner_horizontal.png";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 14,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 80,
    lineHeight: "110%",
  },

  title: {
    fontSize: 18,
    marginBottom: 15,
    marginTop: 90,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 25,
    marginTop: 5,
    fontWeight: "bold",
    textAlign: "center",
  },
  subtitle2: {
    fontSize: 14,
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 10,
    fontWeight: "bold",
    fontStyle: "italic",
    backgroundColor: "#e1e1e1",
    borderLeft: "3px double #3c9e18",
  },
  paragraph: {
    fontSize: 14,
    color: "#555555",
    padding: 8,
    borderBottom:"0.6px solid #3c9e18",
    backgroundColor:"#f6f9fe",
  },

  table: {
    display: "table",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "100%",
  },

  header: {
    position: "absolute",
    top: 30,
    left: 60,
    right: 60,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 40,
  },

  headertext: {
    top: 8,
    fontSize: 14,
  },
  logo: {
    width: "150px",
    height: "auto",
    alignSelf: "flex-end",
  },

  footer: {
    position: "absolute",
    bottom: 30,
    left: 60,
    right: 60,
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1px double #3c9e18",
  },
  pageNumber: {
    top: 10,
    bottom: 10,
    fontSize: 12,
    textAlign: "right",
    fontWeight: "bold",
  },
  address: {
    width: "250px",
    top: 10,
    bottom: 10,
    fontSize: 12,
    textAlign: "left",
    fontWeight: "bold",
  },
});

const PDFDocument = ({ plan, selectedPlan }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View>
            <Text style={styles.headertext}>Radar Systems TLC</Text>
            <Text style={styles.headertext}>
              Telefono: +(58) 212-7814413 / +(58) 212-7819013
            </Text>
            <Text style={styles.headertext}>
              Email: ventas@expomarketing.biz
            </Text>
          </View>
          <View style={styles.logoContainer}>
            <Image src={logo} style={styles.logo} />
          </View>
        </View>

        {/* Content */}
        <Text style={styles.title}>
          Información del Plan {selectedPlan.name}
        </Text>

        <Text style={styles.subtitle}>
          Descubre lo mejor que ofrece RadarSystems
        </Text>

        {plan &&
          plan.details.map((detail, index) => (
            <View style={styles.table} key={index}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={styles.subtitle2}>{detail.title}</Text>
                  {detail.info.map((info, i) => (
                    <View key={i}>
                      <Text style={styles.paragraph}>{info.info1}</Text>
                      <Text style={styles.paragraph}>{info.info2}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          ))}

        {/* Footer */}
        <View style={styles.footer}>
          <View>
            <Text style={styles.address}>
              Dirección: Av. Lima Edif. Torre Phelps Los Caobos, Caracas -
              Distrito Capital{" "}
            </Text>
          </View>
          <View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Página ${pageNumber} de ${totalPages}`
              }
              fixed
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
